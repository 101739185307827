import React, { Component } from "react";
import jexcel from "jexcel";
import "jexcel/dist/jexcel.css";

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import { Button, message, Upload, Dropdown, Menu, Table } from "antd";
import Papa from "papaparse";
import { withTranslation } from "react-i18next";
import {Excel} from "antd-table-saveas-excel";
import {exe} from "../../Lib/Dal";

class Spreadsheet extends Component {
  constructor(props) {
    super();
    this.id = "spreadsheet-" + new Date().valueOf();
  }
  componentDidMount() {
    this.load();
  }
  id;
  instance;
  myDefaultOptions = {
    tableOverflow: true,
    lazyLoading: true,
    loadingSpin: true,
    tableHeight: "600px",
    csvHeaders: true,
    about:false,
    contextMenu: function(obj, x, y, e) {
      var items = [];

      if (y == null) {
        // Insert a new column
        if (obj.options.allowInsertColumn == true) {
          items.push({
            title:obj.options.text.insertANewColumnBefore,
            onclick:function() {
              obj.insertColumn(1, parseInt(x), 1);
            }
          });
        }

        if (obj.options.allowInsertColumn == true) {
          items.push({
            title:obj.options.text.insertANewColumnAfter,
            onclick:function() {
              obj.insertColumn(1, parseInt(x), 0);
            }
          });
        }

        // Delete a column
        if (obj.options.allowDeleteColumn == true) {
          items.push({
            title:obj.options.text.deleteSelectedColumns,
            onclick:function() {
              obj.deleteColumn(obj.getSelectedColumns().length ? undefined : parseInt(x));
            }
          });
        }

        // Rename column
        if (obj.options.allowRenameColumn == true) {
          items.push({
            title:obj.options.text.renameThisColumn,
            onclick:function() {
              obj.setHeader(x);
            }
          });
        }

        // Sorting
        if (obj.options.columnSorting == true) {
          // Line
          items.push({ type:'line' });

          items.push({
            title:obj.options.text.orderAscending,
            onclick:function() {
              obj.orderBy(x, 0);
            }
          });
          items.push({
            title:obj.options.text.orderDescending,
            onclick:function() {
              obj.orderBy(x, 1);
            }
          });
        }
      } else {
        // Insert new row
        if (obj.options.allowInsertRow == true) {
          items.push({
            title:obj.options.text.insertANewRowBefore,
            onclick:function() {
              obj.insertRow(1, parseInt(y), 1);
            }
          });

          items.push({
            title:obj.options.text.insertANewRowAfter,
            onclick:function() {
              obj.insertRow(1, parseInt(y));
            }
          });
        }

        if (obj.options.allowDeleteRow == true) {
          items.push({
            title:obj.options.text.deleteSelectedRows,
            onclick:function() {
              obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
            }
          });
        }

        if (x) {
          if (obj.options.allowComments == true) {
            items.push({ type:'line' });

            var title = obj.records[y][x].getAttribute('title') || '';

            items.push({
              title: title ? obj.options.text.editComments : obj.options.text.addComments,
              onclick:function() {
                obj.setComments([ x, y ], prompt(obj.options.text.comments, title));
              }
            });

            if (title) {
              items.push({
                title:obj.options.text.clearComments,
                onclick:function() {
                  obj.setComments([ x, y ], '');
                }
              });
            }
          }
        }
      }

      // Line
      items.push({ type:'line' });

      // Save
      if (obj.options.allowExport) {
        items.push({
          title: obj.options.text.saveAs,
          shortcut: 'Ctrl + S',
          onclick: function () {
            obj.download();
          }
        });
      }
      // Line
      items.push({ type:'line' });
      // Copy to all rows
      if (obj.options.allowExport) {
        items.push({
          title: "Copy to all",
          onclick: function () {
            const colData=obj.getColumnData(x);
            const newValue=colData[y]
            for (let i = 0; i <colData.length ; i++) {
              colData[i]=newValue;
            }
            obj.setColumnData(x,colData)
          }
        });
      }
      return items;
    }
  };
  load() {
    if(!document.getElementById(this.id)){
      setTimeout(()=>this.load(),1000); //DOM not ready after update to v4. Waiting...
      return;
    }
    this.instance = jexcel(document.getElementById(this.id), {
      data: this.props.data || [],
      columns: this.props.columns,
      minDimensions: [this.props.columns.length, 5],
      ...this.myDefaultOptions,
    });
    this.instance.jexcel = jexcel;
    this.instance.jexcel.myDefaultOptions = this.myDefaultOptions;
    if (this.props.instance) this.props.instance(this.instance);
  }
  componentDidUpdate(prevProps) {
    if ((prevProps.data.length !== this.props.data.length)||(JSON.stringify(prevProps.data)!==JSON.stringify(this.props.data))) {
      if(this.instance) this.instance.setData(this.props.data);
    }
  }
  allowedMimeTypes = [
    "text/plain",
    "text/x-csv",
    "application/vnd.ms-excel",
    "application/csv",
    "application/x-csv",
    "text/csv",
    "text/comma-separated-values",
    "text/x-comma-separated-values",
    "text/tab-separated-values",
  ];
  readFile = (file) => {
    console.log(file);

    //checking if file need preprocessing
    if(this.props.preProcessorId) {
      this.preProcessAndReadFile(file);
      return false;
    }
    
    if (!this.allowedMimeTypes.includes(file.type)) {
      message.error(this.props.t("Only CSV files supported. Please convert the file to CSV"));
      return;
    }
    const me=this;
    me.props.onLoading&&me.props.onLoading(true);

    Papa.parse(file, {skipEmptyLines: true,
      complete: function (results) {
        me.computeResults(me,results,file)
      },
    });
    return false;
  };
  
  computeResults = (me,results,file) => {
    //settings columns
    const columns = results.data[0].map((p) => ({ title: p, width: 100 }));
    //recreating
    if(results.data.length>10000){
      
      window.largeData=results.data.slice(1);//assign to largeData, excluding first row (header)
      
      me.props.onLargeData&&me.props.onLargeData(results.data.length-1); //excluding header
    } else {
      me.props.onLargeData && me.props.onLargeData(null);
      window.largeData=null;
    }

    if(window.spreadsheet){
        window.spreadsheet.destroy();
        const jexcel = window.spreadsheet.jexcel;
        window.spreadsheet = window.spreadsheet.jexcel(window.spreadsheet.el, { columns: columns, data: results.data.slice(1,10000), ...jexcel.myDefaultOptions });
        window.spreadsheet.jexcel = jexcel;
    }
    me.props.instance&&me.props.instance(window.spreadsheet); //updating parent instance
    me.props.onLoading&&me.props.onLoading(false);
    me.props.onFileLoaded&&me.props.onFileLoaded(file.name);
  }
  
  preProcessAndReadFile = (file) => {
    const me=this;
    me.props.onLoading&&me.props.onLoading(true);
    const reader = new FileReader();

    reader.addEventListener('load', function (e) {
      //creating context
        const context={fileContent:e.target.result,fileName:file.name};
      //sending file content to chain for preprocessing
      exe("ExeChain",{chainId:me.props.preProcessorId, context:JSON.stringify(context)}).then((r)=> {
        me.props.onLoading&&me.props.onLoading(false);
        if(r.ok){//chain execution
          if(r.outData&&r.outData.ok){//chain result
            message.success(r.outData.msg);
            console.log(r.outData.outData);
            //try computing results
            try {
              me.computeResults(me,{data:r.outData.outData},file);
            } catch (e) {
              message.error(me.props.t("Error computing chain result. PLease check chain id ")+me.props.preProcessorId);
              console.error(e)
            }
          }else message.error(r.outData.msg);
        }else {
          message.error(r.msg);
        }
      })
    });

    reader.readAsBinaryString(file);
    
  }

  onFilterErrors = () => {
    const allData=window.spreadsheet.allData;
    if(!allData) {
      message.error(this.props.t("Please load a result set first"));
      return;
    }
    const cols = allData[0].length;
    const filteredData = allData.filter((p) => p[cols - 2] == false);
    window.spreadsheet.setData(filteredData);
    filteredData.forEach((p, index) => {
      //need to do this to correctyl update cell values. bug?
      window.spreadsheet.setRowData(index, p);
    });
  };
  onFilterSucceeded = () => {
    const allData=window.spreadsheet.allData;
    if(!allData) {
      message.error(this.props.t("Please load a result set first"));
      return;
    }
    const cols = allData[0].length;
    const filteredData = allData.filter((p) => p[cols - 2] == true);
    window.spreadsheet.setData(filteredData);
    filteredData.forEach((p, index) => {
      //need to do this to correctyl update cell values. bug?
      window.spreadsheet.setRowData(index, p);
    });
  };
  onShowAll = () => {
    const allData=window.spreadsheet.allData;
    if(!allData) {
      message.error(this.props.t("Please load a result set first"));
      return;
    }
    window.spreadsheet.setData(allData);
    allData.forEach((p, index) => {
      window.spreadsheet.setRowData(index, p); //need to do this to correctyl update cell values. bug?
    });
  };
  exportToExcel(){
      const fields = window.spreadsheet.getHeaders().split(",")
      const exportData = window.spreadsheet.getData();
      const parsedData=[];
      exportData.forEach(p=>{
        const item={};
        fields.forEach((q,index)=>{
          item[q]=p[index]
        })
        parsedData.push(item);
      })
    console.log(parsedData)
      const excel = new Excel();
      excel
          .addSheet("Sheet1")
          .addColumns(fields.map((p) => ({ title: p, dataIndex: p }))) 
          .addDataSource(parsedData)
          .saveAs("export.xlsx");
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        {this.props.showExcel&&<Button type={"link"} icon={<LegacyIcon type={"file-excel"} />} onClick={()=>this.exportToExcel()} >{t("Export")}</Button>}
        {this.props.showFileImport && (
          <Upload beforeUpload={(file, fileList) => this.readFile(file)} showUploadList={false}>
            <Button type="link" icon={<UploadOutlined />}>
              {t("Upload File")}
            </Button>
          </Upload>
        )}
        {this.props.showFilterErrors && (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={this.onFilterErrors}>
                  <ExclamationCircleOutlined />
                  {t("Failed")}
                </Menu.Item>
                <Menu.Item onClick={this.onFilterSucceeded}>
                  <CheckCircleOutlined />
                  {t("Succeeded")}
                </Menu.Item>
                <Menu.Item onClick={this.onShowAll}>
                  <PlusCircleOutlined />
                  {t("Original")}
                </Menu.Item>
              </Menu>
            }>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              {t("Filter")} <FilterOutlined />
            </a>
          </Dropdown>
        )}
       
        <div style={{ display: "block" }} id={this.id}></div>
      </div>
    );
  }
}

export default withTranslation()(Spreadsheet);
