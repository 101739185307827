import React, { Component } from "react";
import { Table, Empty, Typography, Tag } from "antd";
import { equal } from "../../Lib/Dal";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (t) => <Typography.Paragraph ellipsis={{ rows: 3, expandable: true }}>{t}</Typography.Paragraph>,
  },
  {
    title: "Code",
    dataIndex: "code",
    width:"110px",
    render: v=> <Tag>{v}</Tag>
  },
];

class CoverageSelect extends Component {
  onSelect = (record, selected, selectedRows, nativeEvent) => {
    const selectedRowKeys = selectedRows.map((r) => r.code);
    console.log("selected before: ", record,selectedRowKeys);
    //checking linked covs
      if (record.linked) {
        //has linked covs
        record.linked.forEach((l) => {
          const linkedCov = this.props.config.Coverages.find((r) => r.code == l);
          //repplicating selection to linked covs, 
          if (linkedCov) {
            if (selected) {
              //selecting linked cov
              if (!selectedRowKeys.includes(linkedCov.code)) selectedRowKeys.push(linkedCov.code);
            } else {
              //deselecting linked cov
              if (selectedRowKeys.includes(linkedCov.code)) selectedRowKeys.splice(selectedRowKeys.indexOf(linkedCov.code), 1);
            }
          }
        });
      }
      if (record.parent) {
        //has parent, selecting parent, if not already present
        if (!selectedRowKeys.includes(record.parent)) selectedRowKeys.push(record.parent);
      }
      //if parent is deselected, deselect all children
      if (!selected) {
        const children = this.props.config.Coverages.filter((r) => r.parent == record.code);
        children.forEach((c) => {
          if (selectedRowKeys.includes(c.code)) selectedRowKeys.splice(selectedRowKeys.indexOf(c.code), 1);
        });
      }

    console.log("selected after: ", selectedRowKeys);
    this.props.onChange(selectedRowKeys);

  }

  render() {
    if (!this.props.config || !this.props.config.Coverages) return <Empty />;
    const excluded = this.props.excluded ? this.props.excluded : [];
    const selected = this.props.selected ? this.props.selected.filter((p) => !excluded.includes(p.code)) : [];
    //.filter((p) => this.props.config.Coverages.map((q) => q.name).includes(p)); //selected only if present in config

    const selectable = this.props.config.Coverages.filter((p) => !excluded.includes(p.code) && !p.parent); //used in add coverage change. Excluded covs already present in policy
    const data = selectable.map((p) => {
      if (p.Subcoverages) {
        p.Subcoverages = p.Subcoverages.filter(p=>!excluded.includes(p.code)).map((q) => ({ ...q, parent: p.code }));
        p.children = [...p.Subcoverages];
      }
      p.mandatory = this.props.mandatoryCoverages ? this.props.mandatoryCoverages.includes(p.code) : false;
      return p;
    });

    const rowSelection = {
      selectedRowKeys: selected,
      //onChange: this.onSelectChange,
      onSelect: this.onSelect,
      getCheckboxProps: (record) => ({
        disabled: record.mandatory,
        name: record.code,
      }),
    };

    return (
      <div>
        <Table columns={columns} pagination={false} dataSource={data} rowSelection={rowSelection} rowKey="code" size="small" />
      </div>
    );
  }
}

export default CoverageSelect;
