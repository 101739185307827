import React, { useState, useEffect } from "react";
import { Select, Tooltip, Divider } from "antd";
import { exe } from "Lib/Dal";
import { useTranslation } from "react-i18next";

//used inside forms = props = fn onChange, string value
const SubLobSelect = (props) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.lobCode) load();
  }, [props.lobCode]);

  const lobCode = props.lobCode || "";

  const load = () => {
    exe("RepoSubLob", { operation: "GET", filter: `lobCode='${lobCode}'` }).then((r) => {
      const results = r.outData || [];
      setData(results);
      if (results.find((p) => p.code == props.value) == null) {
        if (props.onChange) props.onChange(undefined);
      }
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Select
        style={{ width: "100%" }}
        onChange={props.onChange}
        value={props.value || undefined}
        showSearch
        allowClear
        disabled={props.disabled}
        showArrow={true}
        placeholder={t("Please select Sub-LoB")}>
        {data.map((d) => (
          <Select.Option key={d.code} value={d.code}>
            {t(d.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default SubLobSelect;
