import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, DatePicker, InputNumber, Button, Popconfirm, message, Row, Col } from 'antd';
import moment from 'moment';
import { FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { exe } from '../../Lib/Dal';

const CardEdit = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
       
        if(props.selected) {
            form.setFieldsValue(props.selected);
        }
    }, [props.visible, props.selected]);


    const handleOk = () => {
        form.validateFields().then((values) => {
            setLoading(true);
            exe('RepoProjectTaskCard',{operation:"UPDATE",entity:values}).then((res) => {
                setLoading(false);
                if(res.ok){
                    props.onSave(values);
                    message.success(res.msg);
                }else {
                    message.error(res.msg);
                }
            });


        }).catch((info) => {
            form.setFieldsValue({ convertToChild: false });
            console.log('Validate Failed:', info);
        });
    };

    const onDeleteCard = (card) => {
        setLoading(true);
        exe("RepoProjectTaskCard",{operation:"DELETE",entity:{id:card.id}}).then((r) => {
            setLoading(false);
            if(r.ok){
                console.log(r.outData);
                props.onDelete(card)
            }else message.error(r.msg);
        });
    }

    return (
        <Modal
            visible={props.visible}
            title={<span><FileOutlined /> Card {props.selected?.id}</span>}
            width={800}
            onCancel={props.onCancel}
            footer={[
                <Button key="cancel" onClick={props.onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} loading={loading}>
                    Save
                </Button>,
            ]}
        >
            <Row>
                <Col span={20}>
                    <Form form={form} layout="vertical">
                        <Form.Item name="id" hidden>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="projectTaskColId" hidden>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="projectTaskId" hidden>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="projectId" hidden>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="order" hidden>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="status" hidden>
                            <Input type="hidden" />
                        </Form.Item>

                        <Form.Item name="name" label="Title">
                            <Input />
                        </Form.Item>
                        <Form.Item name="content" label="Content">
                            <Input.TextArea autoSize={{minRows:4}}  placeholder="Card content" />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={4}>
                     <Button type={"link"} icon={<DeleteOutlined />} onClick={() => onDeleteCard(props.selected) }>Delete</Button>
                </Col>
            </Row>
           
         
        </Modal>
    );
};

export default CardEdit;
