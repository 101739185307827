import React from "react";
import { Alert, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import { LeftOutlined, PlusOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import PensionMemberBeneficiary from "./PensionMemberBeneficiary";
import ContactLink from "../Contact/ContactLink";

const PensionMemberBeneficiaries = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [selected,setSelected] = useState(null);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        if(props.pensionMemberId){
            load();
        }
    },[props.pensionMemberId])

    const load=()=>{
        setLoading(true);
        exe("RepoPensionBeneficiary",{operation:"GET",filter:`pensionMemberId=${props.pensionMemberId}`,include:["Contact","Relationship"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }

    const percentageSum=data.reduce((a,v)=>a+v.percentage,0);
    console.log(percentageSum);

    return <div>
        {!selected&&<div>
            <Button type="link" icon={<PlusOutlined />} onClick={()=>setSelected({id:0})}>{t("Add")}</Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={load}>{t("Reload")}</Button>
            {percentageSum!==100&&<Alert
            message={t("Warning")}
            description={t("Beneficiaries split should be 100%")}
            type="warning"
            showIcon
          />}
            <Table dataSource={data} rowKey={"id"} loading={loading}>
                <Table.Column title={t("Id")} dataIndex="id"  />
                <Table.Column title={t("Name")} dataIndex={["Contact"]} render={(v,r)=><ContactLink contact={v} />} />
                <Table.Column title={t("Relationship")} dataIndex={["Relationship","name"]} />
                <Table.Column title={t("Split")} dataIndex="percentage"  />
                <Table.Column title={t("Actions")} render={(v,r)=><div>
                    <Button type="link" icon={<EditOutlined />} onClick={()=>setSelected(r)} />
                </div>} />
            </Table>
        </div>}
    {selected&&<div>
        <PensionMemberBeneficiary value={selected} pensionMemberId={props.pensionMemberId} onSave={v=>{setSelected(null);load()}} onCancel={()=>setSelected(null)} />
    </div>}
    </div>
       
    };

    export default PensionMemberBeneficiaries;