import React from "react";
import {
  BankOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SafetyOutlined,
  SearchOutlined,
  SecurityScanOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Button, Table, message, Popconfirm, notification, Select, Tooltip, Input } from "antd";
import { get, put, del, exe } from "../../Lib/Dal";
import EditableCell from "../Shared/EditableCell";
import GrupoDetalle from "./GrupoDetalle";
import GrupoUsuarios from "./GrupoUsuarios";
import { withTranslation } from "react-i18next";
import GroupPermissions from "./GroupPermissions";
import GroupActions from "./GroupActions";
import GroupOrganizations from "./GroupOrganizations";
const Option = Select.Option;

class Grupos extends React.Component {
  state = {
    loading: true,
    data: [],
    new: false,
    edit: false,
    detalleVisible: false,
    usuariosVisible: false,
    tipoMaestro: "grupo", //default
    permissionsVisible: false,
    actionsVisible: false,
    organizationsVisible: false,
  };

  componentDidMount() {
    this.load();
  }

  load() {
    exe("RepoUsrGroup", { operation: "GET", include: ["Members","Organizations"] }).then((r) => {
      this.setState({ loading: false, data: r.outData });
    });
  }

  onEdit = (record) => {
    this.setState({
      edit: true,
      new: false,
      record: record,
      detalleVisible: true,
    });
  };

  onConfirmDeleteRecord = (record) => {
    exe("RepoUsrGroup", { operation: "DELETE", entity: record }).then((r) => {
      if (r.ok) {
        message.success(this.props.t("Record deleted successfully"));
      } else {
        notification.error({
          message: this.props.t("Error"),
          description: r.msg,
        });
      }

      this.load();
    });
  };
  onAdd = () => {
    this.setState({ new: true, edit: false, detalleVisible: true });
  };
  afterAdd = () => {
    this.load();
    this.setState({ detalleVisible: false, new: false });
  };
  onClose = () => {
    this.setState({ detalleVisible: false, new: false, record: null });
  };
  onTipoMaestroChange(v) {
    this.getData(v);
  }
  onShowUsers = (record) => {
    this.setState({ usuariosVisible: true, record: record });
  };
  onCloseUsuarios = () => {
    this.setState({ usuariosVisible: false, record: null });
  };
  afterOkUsuarios = () => {
    this.load();
    this.setState({ usuariosVisible: false, record: null });
  };
  onShowPermissions = (record) => {
    this.setState({ permissionsVisible: true, record: record });
  };
  onPermissionsUpdate = (updated) => {
    const updatedData = this.state.data.map((p) => (p.id == updated.id ? { ...p, jPermissions: updated.jPermissions } : p));
    this.setState({ permissionsVisible: false, record: null, data: updatedData });
  };
  onShowActions = (record) => {
    this.setState({ actionsVisible: true, record: record });
  };
  onShowOrganizations = (record) => {
    this.setState({ organizationsVisible: true, record: record });
  };
  onActionsUpdate = (updated) => {
    const updatedData = this.state.data.map((p) => (p.id == updated.id ? { ...p, jActions: updated.jActions } : p));
    this.setState({ actionsVisible: false, record: null, data: updatedData });
  };
  onOrganizationsUpdate = (updated) => {
    this.load();
    this.setState({ organizationsVisible: false, record: null });
  };
  ///FILTER START
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(ele) => (this.searchInput = ele)}
          placeholder={`${this.props.t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(this.state.selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          {this.props.t("Search")}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {this.props.t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => (record[dataIndex] || "").toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys ? selectedKeys[0] : "", searchedColumn: dataIndex });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  //// FILTER END
  render() {
    const { t, i18n } = this.props;
    const columns = [
      {
        title: t("Id"),
        dataIndex: "id",
      },
      {
        ...this.getColumnSearchProps("name"),
        title: t("Name"),
        dataIndex: "name",
      },
      {
        ...this.getColumnSearchProps("memberCount"),
        title: t("Members"),
        dataIndex: "memberCount",
      },

      //columnas particualres
      //   {
      //     title: this.state.tipoMaestro=="estadosiniestro"?"% Avance":null,
      //     dataIndex: "pAvance"
      //   },
      {
        title: t("Actions"),
        key: "acciones",
        render: (text, record) => (
          <div>
            <Popconfirm
              title={t("Are you sure you want to remove this group?")}
              onConfirm={(p) => this.onConfirmDeleteRecord(record)}
              okText={t("Yes")}
              cancelText={t("No")}>
              <Tooltip title={t("Delete Group")}>
                <Button icon={<DeleteOutlined />} style={{ marginLeft: 5 }} />
              </Tooltip>
            </Popconfirm>
            <Tooltip title={t("Edit Group")}>
              <Button icon={<EditOutlined />} style={{ marginLeft: 5 }} onClick={(p) => this.onEdit(record)} />
            </Tooltip>
            <Tooltip title={t("Group Membership")}>
              <Button icon={<TeamOutlined />} style={{ marginLeft: 5 }} onClick={(p) => this.onShowUsers(record)} />
            </Tooltip>
            <Tooltip title={t("Screen Permissions")}>
              <Button icon={<SafetyOutlined />} style={{ marginLeft: 5 }} onClick={(p) => this.onShowPermissions(record)} />
            </Tooltip>
            <Tooltip title={t("Action Permissions")}>
              <Button icon={<SecurityScanOutlined />} style={{ marginLeft: 5 }} onClick={(p) => this.onShowActions(record)} />
            </Tooltip>
            <Tooltip title={t("Group Organizations")}>
              <Button icon={<BankOutlined />} style={{ marginLeft: 5 }} onClick={(p) => this.onShowOrganizations(record)} />
            </Tooltip>
          </div>
        ),
      },
    ];
    return (
      <div>
        <Button onClick={this.onAdd} type="primary" style={{ marginBottom: 16 }} icon={<PlusOutlined />}>
          {t("New")}
        </Button>
        {/* <Select defaultValue="estadopoliza" style={{ width: 120,marginLeft:5 }} onChange={p=>this.onTipoMaestroChange(p)}>
          <Option value="estadopoliza">Pólizas</Option>
          <Option value="estadosiniestro">SIniestros</Option>
        </Select> */}
        <Table loading={this.state.loading} dataSource={this.state.data} columns={columns} rowKey="id" size="middle" />
        <GrupoDetalle
          visible={this.state.detalleVisible}
          onClose={this.onClose}
          afterAdd={this.afterAdd}
          new={this.state.new}
          record={this.state.record}
          tipoMaestro={this.state.tipoMaestro}
        />
        <GrupoUsuarios visible={this.state.usuariosVisible} onClose={this.onCloseUsuarios} grupo={this.state.record} afterOk={this.afterOkUsuarios} />
        <GroupPermissions
          record={this.state.record}
          visible={this.state.permissionsVisible}
          onCancel={() => this.setState({ permissionsVisible: false, record: null })}
          onUpdate={(updated) => this.onPermissionsUpdate(updated)}
          allMenuItems={this.props.allMenuItems}
        />
        <GroupActions
          record={this.state.record}
          visible={this.state.actionsVisible}
          onCancel={() => this.setState({ actionsVisible: false, record: null })}
          onUpdate={(updated) => this.onActionsUpdate(updated)}
        />
        <GroupOrganizations
          record={this.state.record}
          visible={this.state.organizationsVisible}
          onCancel={() => this.setState({ organizationsVisible: false, record: null })}
          onUpdate={(updated) => this.onOrganizationsUpdate(updated)}
        />
      </div>
    );
  }
}
export default withTranslation()(Grupos);
