import React from "react";
import { d3, CalHeatMap } from "sis11deps";
import { exe } from "Lib/Dal";
import {withTranslation} from "react-i18next";
import moment from "moment/moment";

class HeatMap extends React.Component {
  hmInstance;
  componentDidMount() {
    exe("GetHeatmap", { mode: "claims" }).then((r) => {
      if (this.props.onLoad) this.props.onLoad();
      const data = r.outData.reduce((acc, item, index) => {
        acc[item.timestamp] = item.count;
        return acc;
      }, {});
      //   const data1 = this.props.data || {
      //     "1548174120": 5,
      //     "1552174120": 5,
      //     "1553174120": 5,
      //     "1554174120": 5,
      //     "1555174120": 10,
      //     "1559584120": 23,
      //     "1562705556": 15
      //   };
      //console.log(r.outData, data, data1, "HEATMAP DATA");

      const year = this.props.year || new Date().getFullYear();

      const hm = new CalHeatMap();
      hm.init({
        domain: "month",
        subDomain: "day",
        range: 12,
        start: new Date(year, 0, 1),
        data: data,
        itemName:[this.props.t("claim"), this.props.t("claims")],
        subDomainTitleFormat: { empty: "{date}", filled: "{count} {name} - {date}" },
        subDomainDateFormat: function(date) {
          return moment(date).local().format("dddd MMMM Do, YYYY");
        },
        domainLabelFormat: function(date) {
          return moment(date).local().format("MMMM");
        },
        onClick: (date, nb) => {
          window.parent.location.hash =
            "#/claimList?date=" + date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
        },
      });
      this.hmInstance = hm;
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.year !== prevProps.year) {
      this.hmInstance.jumpTo(new Date(this.props.year, 0, 1), true);
    }
  }
  componentWillUnmount() {
    if (this.hmInstance) {
      this.hmInstance=this.hmInstance.destroy();
    }
  }

  render() {
    return <div id="cal-heatmap" />;
  }
}
export default withTranslation()(HeatMap);