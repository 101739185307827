import {
  CheckOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  InboxOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SaveOutlined,
  SearchOutlined, DiffOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Menu, message, Popconfirm, Select, Table, Tabs, Tag, Tree } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeGetRaw } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import Spreadsheet from "../Shared/Spreadsheet";
import AccountDetail from "./AccountDetail";
import CoaSearch from "./CoaSearch";
import { getConfigProfile } from '../../Lib/Helpers';
import BranchSelect from '../Financial/BranchSelect';

const ChartOfAccounts = (props) => {
  const [data, setData] = useState([]);
  const [flat, setFlat] = useState([]);
  const [spreadsheet, setSpreadsheet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [accountDetailVisible, setAccountDetailVisible] = useState(false);
  const [selectedNode, setSelectedNode] = useState();
  const [newOperation, setNewOperation] = useState(true);
  const [showParentLevelsInCode, setShowParentLevelsInCode] = useState(true);
  const [accountMask, setAccountMask] = useState();
  const [separator, setSeparator] = useState();
  const [showSeparator, setShowSeparator] = useState(true);
  const [showCatalogCode, setShowCatalogCode] = useState(true);
  const [catalogs, setCatalogs] = useState([]);
  const [selectedCatalog, setSelectedCatalog] = useState();
  const [branchSelected,setBranchSelected]=useState();

  useEffect(() => load(), []);
  useEffect(() => {
    getConfigProfile().then(profile=>{
      setShowParentLevelsInCode(safeGetRaw(["Accounting","showParentLevelsInCode"],profile,true));
      const accMask=safeGetRaw(["Accounting","accountMask"],profile,null);
      if(!accMask) 
      {
          message.warning(t("Account mask not defined in profile. Please define it in order to use the chart of accounts."));
      }else{
        setAccountMask(accMask);
        //getting the separator, which is the first non alphanumeric character
        const separator=accMask.match(/\W/);
        setSeparator(separator);
      }
      setShowSeparator(safeGetRaw(["Accounting","showSeparator"],profile,true));
      setShowCatalogCode(safeGetRaw(["Accounting","showCatalogCode"],profile,true));

    })}, []);

    useEffect(() => {
      //selecting the first catalog if not selected
      if(!showCatalogCode&&!selectedCatalog&&catalogs.length) setSelectedCatalog(catalogs[0]);
    }
      , [showCatalogCode]);

    useEffect(() => {
      renderNodes(data);
    }, [branchSelected]);

  const columns = [
    //spreadsheet columns
    { title: t("Code"), width: 200 },
    { title: t("Name"), width: 400 },
    { title: t("Parent"), width: 200 },
    { title: t("Currency"), width: 60 },
    { title: t("Type"), width: 50 },
    { title: t("Nature"), width: 50 },
    { title: t("Auxiliary"), width: 60 },
    { title: t("Level") },
    { title: t("Detail"),  type: "checkbox" },
    { title: t("Business Type Code"), width: 60 },
    { title: t("Branches"), width: 200 }
  ];
  
  const loadFlat=()=>{
    setLoading(true);
    exe("RepoCatalogAccount",{operation:"GET"}).then(r=>{
      setLoading(false);
      if(r.ok){
        setFlat(r.outData);
        setTableStyle(r.total);
      }else message.error(r.msg);
    })
  }
  const setTableStyle=(records)=>{
    const styleObj={};
    for (let i = 0; i < records; i++) {
      styleObj["A"+(i+1)]="font-weight:bold;text-align:left";
      styleObj["B"+(i+1)]="text-align:left";
      styleObj["C"+(i+1)]="text-align:left";
    }
    spreadsheet.setStyle(styleObj);
  }

  const load = () => {
    setLoading(true);
    //if we are in an org with rootAccount defined, we will load only the root account
    window.GLOBAL.checkGlobalIsLoaded().then(() => {
      const rootAccount=safeGetRaw(["currentUser","Organization","rootAccount"],window.GLOBAL,false);
      const filter=rootAccount?`code='${rootAccount}'`:"parentCode is null OR parentCode=''";
      exe("RepoCatalogAccount", { operation: "GET",filter:filter}).then((r) => {
        setLoading(false);
        if (r.ok) {
          setData(r.outData);
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const loadTreeNode= treeNode => {
    return new Promise(resolve => {
      if (treeNode.children) {
        resolve();
        return;
      }
      exe("RepoCatalogAccount", { operation: "GET",filter:`parentCode='${treeNode.data.code}'` }).then((r) => {
        if (r.ok) {
            treeNode.data.children =r.total?r.outData:[{key:"-1"}];
            setData([...data]);
        } else message.error(r.msg);
        resolve();
      });
    });
  }
  const save = () => {
    const parsedData = spreadsheet
      .getData()
      .filter((p) => p[0] !== "" && p[1] !== "")
      .map((p) => ({ code: p[0], name: p[1], parentCode: p[2] ? p[2] : undefined,currency:p[3],accountType:p[4],nature:p[5],auxType:p[6],level:p[7],isDetail:p[8],businessTypeCode:p[9]?p[9]:undefined,jBranches:p[10]?p[10]:undefined }));
    if (parsedData.length == 0) {
      message.error("No data detected");
      return;
    }
    setLoading(true);
    exe("RepoCatalogAccount", { operation: "MERGE", bulkJson: JSON.stringify(parsedData) }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
      } else {
        message.error(r.msg);
      }
    });
  };
  const renderCode=(codeRaw)=>{
    let code=codeRaw;
    if(!showCatalogCode){
      //getting from mask char length of catalog code and removing it from the code ie. CCC-XXX-XX-X => XXX-XX-X
      const catalogCodeLength=accountMask.split("C").length;
      if(code.includes(separator)) code=code.substr(catalogCodeLength);      
    }else{
      //building the catalog combo
      if(accountMask){ //waiting for account mask to be loaded
        const catalogCodeLength=accountMask.split("C").length-1;
        const catalogCode=code.substr(0,catalogCodeLength);
        if(!catalogs.includes(catalogCode)) catalogs.push(catalogCode);

      }
    }
    if(!showParentLevelsInCode){
      code=code.substr(code.lastIndexOf(separator)+1);
    }
    if(!showSeparator){
      //removing every separator character
      // Create a regular expression from the separator variable
      let regex = new RegExp(separator, 'g');
      // Replace all instances of the separator in the string
      return code.replace(regex, '');
    }
    return code;
  }
  
  const renderNodes = (data) => {
    if(data&&data.length===1&&data[0].key==="-1") return <Tree.TreeNode icon={<InboxOutlined />} key="-1" isLeaf={true} title={t("Empty")} />;
    //if a branch is selected we will filter the data, taking into account that jBranches is a serialized array
    if(branchSelected){
      data=data.filter(p=>p.jBranches&&JSON.parse(p.jBranches).includes(branchSelected));
      if(data.length===0) return <Tree.TreeNode icon={<InboxOutlined />} key="-1" isLeaf={true} title={t("Empty (Filtered)")} />;
    }
    return data.map((r) => (
      <Tree.TreeNode
        key={r.code}
        data={r}
        isLeaf={r.isDetail}
        title={
          <span>
            <Tag>{renderCode(r.code)}</Tag>
            {r.name}
            <Tag color="green" style={{marginLeft:10,fontSize:"10px"}}>L{r.level}</Tag>
          </span>
        }>
        {r.children&&r.children.length&&renderNodes(r.children)}
      </Tree.TreeNode>
    ));
  };
  const onNodeSelect = (selectedKeys, item) => {
    if (selectedKeys.length == 0) {
      setSelectedNode(undefined);
    } else {
      setSelectedNode(item.node.data);
    }
  };
  const onAddAChildccount = () => {
    setNewOperation(true);
    setAccountDetailVisible(true);
  };
  const onAddRootAccount = () => {
    setNewOperation(true);
    setSelectedNode(undefined);
    setAccountDetailVisible(true);
  };
  const onAccountAdded = () => {
    setAccountDetailVisible(false);
   reload();
  };
  const onEditAccount = () => {
    setNewOperation(false);
    setAccountDetailVisible(true);
  };
  const onDeleteAccount = () => {
    setLoading(true);
    exe("RepoCatalogAccount", { operation: "DELETE", entity: selectedNode }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        reload();
      } else {
        message.error(r.msg);
      }
    });
  };
 const reload=()=>{
   //window.location.reload(false);
   load();
 }
 const validate=()=>{
   setLoading(true);
   exe("ValidateCoa",{all:true}).then(r=>{
     setLoading(false);
     console.log(r.outData)
     if(r.ok){
       if(r.total===0){
         message.success(t("Validation passed"))
       }else message.warning(r.total+t(" finding(s). Please check console."))
     }else message.error(r.msg);
   })
 }
 const onShowMovements=()=>{
    props.history.push("/accounting/movements?code="+selectedNode.code);
  }
  const onBranchSelect=(v)=>{
    setBranchSelected(v);
  } 

  return (
    <div>
      <DefaultPage icon="menu-unfold" title={t("Chart of Accounts")} loading={loading}>
        <Tabs>
        
          <Tabs.TabPane tab={<span><MenuUnfoldOutlined />{t("Tree")}</span>} key="tree" >
          <div style={{marginBottom:10, display:"flex"}}>
            <Button type="link" icon={<ReloadOutlined />} onClick={() =>reload() }>
              {t("Reload")}
            </Button>
            <Button type="link" icon={<EditOutlined />} onClick={onEditAccount} disabled={!selectedNode}>
              {t("Edit Account")}
            </Button>
            <Button type="link" icon={<PlusCircleOutlined />} onClick={onAddAChildccount} disabled={!selectedNode}>
              {t("Add Child Account")}
            </Button>
            <Button type="link" icon={<PlusCircleOutlined />} onClick={onAddRootAccount}>
              {t("Add Root Account")}
            </Button>
            <Popconfirm title={t("Are you sure you want to delete this account?")} onConfirm={onDeleteAccount} disabled={!selectedNode}>
              <Button type="link" icon={<DeleteOutlined />} disabled={!selectedNode}>
                {t("Delete Account")}
              </Button> 
            </Popconfirm>
            <Dropdown overlay={<Menu>
              <Menu.Item key="1">
            <Checkbox checked={showParentLevelsInCode} onChange={(e)=>setShowParentLevelsInCode(e.target.checked)}>{t("Show parent levels")}</Checkbox>
            </Menu.Item>
            <Menu.Item key="2">
            <Checkbox checked={showSeparator} onChange={(e)=>setShowSeparator(e.target.checked)}>{t("Show separator")}</Checkbox>
            </Menu.Item>
            <Menu.Item key="3" >
            <Checkbox onClick={e=>e.stopPropagation()} checked={showCatalogCode} onChange={(e)=>{setShowCatalogCode(e.target.checked);e.stopPropagation()}}>{<span onClick={e=>e.stopPropagation()}>{t("Filter by catalog code")}</span>}</Checkbox>
            </Menu.Item>
            <Menu.Item key="4">
            {!showCatalogCode&&<Select mode="single" style={{ width: 200 }} placeholder={t("Select a catalog")} onClick={e=>e.stopPropagation()}  
            value={selectedCatalog} onChange={(value)=>setSelectedCatalog(value)}>
              {catalogs.map((c)=><Select.Option key={c} value={c}>{c}</Select.Option>)}
            </Select>}
            </Menu.Item>
            </Menu>}>
              <Button type="link" icon={<MenuUnfoldOutlined />}>{t("Options")}</Button>
            </Dropdown>
            <Button type="link" icon={<DiffOutlined />} onClick={onShowMovements} disabled={!selectedNode}>
              {t("Movements")}
            </Button>
            <BranchSelect onChange={onBranchSelect} onClear={()=>onBranchSelect(undefined)} style={{width: 200}} placeholder={t("Branch Filter")} />
            </div>
            <Tree showLine={true} showIcon onSelect={onNodeSelect} loadData={loadTreeNode} loadedKeys={[]}>
              {renderNodes(data.filter(p=>!showCatalogCode&&selectedCatalog?p.code.startsWith(selectedCatalog):true))}
            </Tree>
          </Tabs.TabPane >
          <Tabs.TabPane tab={<span><OrderedListOutlined />{t("Flat")}</span>} key="flat" >
            <Button type="link" icon={<DownloadOutlined />} onClick={() => loadFlat()} style={{ float: "left" }}>
              {t("Load")}
            </Button>
            {/*<Button type="link" icon="reload" onClick={() => load()} style={{ float: "left" }}>*/}
            {/*  {t("Reload")}*/}
            {/*</Button>*/}
            <Button type="link" icon={<SaveOutlined />} onClick={() => save()} loading={loading} style={{ float: "left" }}>
              {t("Save")}
            </Button>
            <Button type="link" icon={<CheckOutlined />} onClick={() => validate()} style={{ float: "left" }}>
              {t("Validate")}
            </Button>
            <Spreadsheet
              instance={(t) => {
                setSpreadsheet(t);
                window.spreadsheet = t;
              }}
              data={flat.map(p=>[p.code,p.name,p.parentCode,p.currency,p.accountType,p.nature,p.auxType,p.level,p.isDetail,p.businessTypeCode,p.jBranches])}
              columns={columns}
              showFileImport
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span><SearchOutlined />{t("Search")}</span>} key="search">
            <CoaSearch />
          </Tabs.TabPane>
        </Tabs>
        <AccountDetail
          selected={selectedNode}
          new={newOperation}
          visible={accountDetailVisible}
          onCancel={() => setAccountDetailVisible(false)}
          onOk={onAccountAdded}
          separator={separator}
          data={data}
        />
      </DefaultPage>
    </div>
  );
};

export default ChartOfAccounts;
