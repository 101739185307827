import React from "react";
import { Tabs } from "antd";
import Usuarios from "./Usuarios";
import Grupos from "./Grupos";
import Estados from "./Estados";
import CatalogEditor from "./CatalogEditor";
import DefaultPage from "../Shared/DefaultPage";
import ConfigProfile from "./ConfigProfile";
import Language from "./Language";
import { withTranslation } from "react-i18next";
import UserChangeHistory from "./UserChangeHistory";
import OrganizationStructure from "../Settings/OrganizationStructure";

const TabPane = Tabs.TabPane;

class Configuracion extends React.Component {
  render() {
    const { t, i18n } = this.props;
    return (
      <DefaultPage title={t("Config")} icon="setting">
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("Users")} key="1">
            <Usuarios configProfile={this.props.configProfile} />
          </TabPane>
          <TabPane tab={t("Groups")} key="2">
            <Grupos allMenuItems={this.props.allMenuItems} />
          </TabPane>
          <TabPane tab={t("Catalogs")} key="3">
            <CatalogEditor />
          </TabPane>
          <TabPane tab={t("Advanced")} key="f">
            <ConfigProfile />
          </TabPane>
          <TabPane tab={t("History")} key="history">
            <UserChangeHistory />
          </TabPane>
          <TabPane tab={t("Organization Structure")} key="orgStructure">
            <OrganizationStructure />
          </TabPane>

        </Tabs>
      </DefaultPage>
    );
  }
}
export default withTranslation()(Configuracion);
