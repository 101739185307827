import React, {useEffect, useRef, useState} from "react";
import { BellTwoTone, CloseOutlined, DeleteOutlined, ApiOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Drawer, Tabs, Empty, Space, Badge, Typography, Divider, Button, Tag, Modal } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { exe } from "../Lib/Dal";
const Paragraph=Typography.Paragraph;

const Notifications = (props) => {
  const [data,setData]=useState([]);
  const [message,setMessage]=useState();
  const [t] = useTranslation();
  const [loading,setLoading]=useState(false);

  const latest = useRef(); //real-time last message
    
    useEffect(()=>{
        window.channel.onmessage=dataFromOtherTab=>{
            console.log("From broadcast channel",dataFromOtherTab.data);
            if(dataFromOtherTab.data==="disconnection"){
                //leader disconnection, new leader needed
                if(!localStorage.leader) {
                    setTimeout(()=> props.onNewLeader(),1000);//if both tabs are closed, localStorage.leader becomes orphan. Waiting to assure this tab not being closed
                }
            }else {
                parseReceivedMessage(dataFromOtherTab.data);
            }
        }
        
    },[]);
  
  useEffect(()=>{
      if(!props.notifications) return;
      console.log("Adding onmessage handler");
      props.notifications.onmessage =  (event)=> {
          console.log(event);
            if(event.data==="{}") return;//ignoring keepalive signals
            window.channel.postMessage(event.data); //across other tabs
            parseReceivedMessage(event.data);
       
    };
      
  },[props.leaderLoaded])
    
    const parseReceivedMessage=(eventData)=>{
        try {
            const jNotification=JSON.parse(eventData);
            //checking message not already present. Prevent duplicates
            const exists=latest.current&&latest.current.title===jNotification.title&&latest.current.msg===jNotification.msg;
            if(exists) return;else latest.current=jNotification;
            setMessage({id:Math.random().toString().slice(2),date:new moment(),...jNotification});
        }catch (e) {
            console.error(e);
        }
    }
    
useEffect(()=>{
    if(message) {
        setData([...data,message])
        props.onNewMessage(data.length+1);
        //setting messages accesible globally for background processes promise resolution
        window.GLOBAL.notifications=[...data,message];
    }
},[message])
    
    const directData=data.filter(p=>!p.group);
  const groupData=data.filter(p=>p.group);
  const onCheckConnection=()=>{
    setLoading(true);
    exe("SendNotification",{title:t("Connection Test"),msg:t("Connection Test id ")+Date.now(),usr:localStorage.email,env:window.GLOBAL.currentUser.environment}).then((r)=>{
        setLoading(false);
        if(r.ok){
            Modal.confirm({
                title: t("Connection Test Result"),
                icon: <ExclamationCircleOutlined />,
                okText: t("Message Received"),
                cancelText: t("Fix"),
                content: t("If you did not receive the test notification, please click the fix button to refresh the communication channel and try again"),
                onOk() {
                  console.log('OK');
                },
                onCancel() {
                    localStorage.removeItem("leader");
                    window.location.reload();
                },
              });
        }else message.error(r.msg);
    })

  }
  return (
      <Drawer
        title={
          <span>
            <BellTwoTone style={{ marginRight: 5, fontSize: 18 }} />
            {t("Notifications")}
          </span>
        }
        visible={props.visible}
        onClose={props.onClose}
        placement="left"
        bodyStyle={{paddingTop:5}}
        width={500}>
            <Button type="link" icon={<DeleteOutlined />} style={{padding:0}} onClick={()=>setData([])}>{t("Clear")}</Button>
            <Button type="link" icon={<ApiOutlined />} onClick={onCheckConnection} >{t("Check Connection")}</Button>
        <Tabs>
          <Tabs.TabPane  tab={<div>
              {t("Direct")}
              <Badge style={{ height: 15, lineHeight: "15px",backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }} offset={[4, 0]} count={directData.length} />
          </div>} key="direct">
              {directData.length===0&&<Empty />}
              {directData.sort((a,b)=>b.date.unix()-a.date.unix()).map(m=><div key={m.id}>
                  <div style={{display:"flex"}}>
                      <Badge status={m.result} />
                      <Paragraph strong >{m.title}</Paragraph>
                      <Button type="link" icon={<CloseOutlined />} style={{marginLeft:"auto"}} onClick={()=>setData(data.filter(p=>p.id!==m.id))} />
                  </div>
                  <Paragraph style={{whiteSpace:"pre-line"}}><div dangerouslySetInnerHTML={{ __html: m.msg }} /></Paragraph>
                  <div style={{float:"right",marginBottom:5}}>
                      {m.date.fromNow()}
                  </div>
                  <Divider />
              </div>)}
          </Tabs.TabPane>
          <Tabs.TabPane tab={<div>
              {t("Group")}
              <Badge style={{ height: 15, lineHeight: "15px",backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }} offset={[4, 0]} count={groupData.length} />
          </div>} key="group">
              {groupData.length===0&&<Empty />}
              {groupData.sort((a,b)=>b.date.unix()-a.date.unix()).map(m=><div key={m.id}>
                  <div style={{display:"flex"}}>
                      <Badge status={m.result} />
                      <Paragraph strong >{m.title}</Paragraph>
                      <Button type="link" icon={<CloseOutlined />} style={{marginLeft:"auto"}} onClick={()=>setData(data.filter(p=>p.id!==m.id))} />
                  </div>
                  <Paragraph style={{whiteSpace:"pre-line"}}><div dangerouslySetInnerHTML={{ __html: m.msg }} /></Paragraph>
                  <Tag>{m.group}</Tag>
                  <div style={{float:"right",marginBottom:5}}>
                      {m.date.fromNow()}
                  </div>
                  <Divider />
              </div>)}
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
  );
};

export default Notifications;
