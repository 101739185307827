import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select} from "antd";

const BranchesSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [value,setValue]=useState([]);
    const [loaded,setLoaded]=useState(false);
    
    useEffect(()=>{
       load();
    },[])

    useEffect(()=>{
        if(props.value){
            //value is a serialized array
            let v=JSON.parse(props.value);
            setValue(v);
        }else setValue([]);
    },[props.value])

    useEffect(()=>{
        if(loaded){
            console.log(props.parent);
            //should inherit the branches from the parent, if the parent is selected
            if(props.parent&&props.parent.jBranches){
                if(props.new){
                    const parentBranches=JSON.parse(props.parent.jBranches);
                    onChange(parentBranches);
                } 
                //setting disabled to true for all branches that are not in the parent branches
                setData(data.map(p=>{return {...p,disabled:!JSON.parse(props.parent.jBranches).includes(p.code)}}));
            }else
                {
                //no parent, by default select all branches. Ensure that the branches are loaded. If not loaded, wait for the load
                if(props.new){
                    onChange(data.map(p=>p.code));
                }
                //enabling all
                setData(data.map(p=>{return {...p,disabled:false}}));
                }
            }
    },[props.new,props.parent,loaded,props.parent&&props.parent.code])


    
    const load=()=>{
        setLoading(true);
        exe("RepoBranch",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
                setLoaded(true);
            }else message.error(r.msg)
        })
    }
    const onChange=(v)=>{
        props.onChange && props.onChange(JSON.stringify(v));
    }

    return (
        <div>
          <Select mode={"multiple"} value={value} onChange={onChange} disabled={props.disabled} loading={loading} style={props.style}>
              {data.map(p=><Select.Option value={p.code} key={p.code} disabled={p.disabled}>{p.name}</Select.Option> )}
          </Select>  
        </div>
    );
};

export default BranchesSelect;