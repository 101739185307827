import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DollarOutlined } from '@ant-design/icons';
import {Table, Button, message, Tag, Space, Tooltip} from "antd";
import ContractReinstatementForm from "./ContractReinstatementForm";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import Status from '../Shared/Status';
import { getColor } from '../../Lib/Helpers';

const ContractReinstatement = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.contractId) load();
    },[props.contractId])
    
    const load=()=>{
        setLoading(true);
        exe("RepoContractInstallment",{operation:"GET",filter:`contractId=${props.contractId}`,include:["Contact","Process"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    
    const onSave=values=>{
        let operation;
        if(values.id){
            operation="UPDATE";
        }else{
            operation="ADD"
            values.contractId=props.contractId;
        }
        setLoading(true);
        exe("RepoContractInstallment",{operation:operation,entity:values}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })
        
    }
    const onDelete=record=>{
        setLoading(true);
        exe("RepoContractInstallment",{operation:"DELETE",entity:record}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })

    }
    const getStatus=v=>{
        switch (v){
            case 1:
                return <Tag color={"blue"}>{t("Paid")}</Tag>
            case 3:
                return <Tag color={"green"}>{t("Requested")}</Tag>
            default:
                return <Tag> {t("Pending")}</Tag>
        }
    }
    const onPay=(installmnetId)=>{
        setLoading(true);
        exe("DoContractInstallmentPayment",{contractInstallmentId:installmnetId}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                load();
            }else message.error(r.msg);
        })
    }
    
    return (
        <div>
            {!selected&&<div>
                <Button type={"primary"} icon={<LegacyIcon type={"plus"} />} onClick={()=>setSelected({id:0})}>{t("New")}</Button>
                <Button type={"link"} icon={<LegacyIcon type={"reload"} />} onClick={()=>load()}>{t("Reload")}</Button>
                <Table dataSource={data} rowKey={"id"} style={{marginTop:5}} loading={loading}>
                    <Table.Column title={t("Installment")} dataIndex={"installmentNum"} />
                    <Table.Column title={t("Payment Date")} dataIndex={"payDate"} />
                    <Table.Column title={t("Beneficiary")} dataIndex={"contactId"} render={(v,r)=>r.Contact?r.Contact.FullName:""} />
                    <Table.Column title={t("Line Id")} dataIndex={"lineId"} />
                    <Table.Column title={t("Status")} dataIndex={"status"} render={v=>getStatus(v)} />
                    <Table.Column title={t("Amount")} dataIndex={"amount"} />
                    <Table.Column title={t("Currency")} dataIndex={"currency"} />
                    <Table.Column title={t("Type")} dataIndex={"typeCode"} />
                    <Table.Column title={t("Workflow")}dataIndex="Process" render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load()} />}/>
                    <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><Space>
                        <Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} /> 
                        <Tooltip title={t("Request Payment")}>
                            <Button type={"link"} icon={<DollarOutlined />} onClick={()=>onPay(r.id)} disabled={r.status==3} /> 
                        </Tooltip>
                        </Space>}/>
                </Table>
            </div>}
            {selected&&<ContractReinstatementForm onBack={()=>setSelected(null)} onSave={onSave} selected={selected} onDelete={onDelete} />}
        </div>
    );
};

export default ContractReinstatement;