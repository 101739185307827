import React, { Component, useEffect, useState } from "react";
import {Input, InputNumber} from "antd";
import getCache from "../../Lib/Cache";
import { NumericFormat } from 'react-number-format';
import {currencyFormatter, getCurrencyDecimals} from "../../Lib/Helpers";

const defaultCurrencyRecord={code:"USD",symbol:"$", thousandSeparator:",", decimalSeparator:".",currencyDecimals:2};

const Money = (props) => {
  const [currencyRecord, setCurrencyRecord] = useState(defaultCurrencyRecord);
  useEffect(() => {
    if (props.currency) {
        getCache("RepoCurrency").then((r) => {
            let record=defaultCurrencyRecord;
            if (r.ok) {
                const r1 = r.outData.find((p) => p.code === props.currency);
                if (r1) record = r1;
            }
            //if currency has the form ABC-DEF, then ABC is the currency
            const currency=props.currency.split("-")[0];
            const currencyDecimals=getCurrencyDecimals(currency);
            //obtaining info from formatter
            const parts= currencyFormatter(currency).formatToParts(10000);
            const decimalSeparator=parts.find(p=>p.type==="decimal");
            const thousandSeparator=parts.find(p=>p.type==="group");
            const symbol=parts.find(p=>p.type==="currency");
            
            setCurrencyRecord({
                code:props.currency, 
                symbol:record.symbol||(symbol?symbol.value:defaultCurrencyRecord.symbol), 
                decimalSeparator:currencyDecimals==0?"%":(decimalSeparator?decimalSeparator.value:defaultCurrencyRecord.decimalSeparator), //ignoring if 0 decimals because it cannot be the same as thousandSeparator
                thousandSeparator:thousandSeparator?thousandSeparator.value:defaultCurrencyRecord.thousandSeparator,
                currencyDecimals:currencyDecimals
            });
            }
        );
    }
  }, [props.currency]);
  
  const getCurrencyRecord = (iso) =>
    getCache("RepoCurrency").then((r) => {
      if (r.ok) {
        const record = r.outData.find((p) => p.code === iso);
        if (record) setCurrencyRecord(record);
      }
    });

  

  return (
        <NumericFormat 
            displayType={props.displayType}
            value={(props.value !== null && props.value !== undefined)?props.value:""}  //fix for null values
            onValueChange={values=>props.onChange&&props.onChange(values.floatValue)}
            thousandSeparator={currencyRecord.thousandSeparator||defaultCurrencyRecord.thousandSeparator}
            decimalSeparator={currencyRecord.decimalSeparator||defaultCurrencyRecord.decimalSeparator}
            decimalScale={currencyRecord.currencyDecimals} //limits the number of decimals
            prefix={(currencyRecord.symbol||defaultCurrencyRecord.symbol)+" "}
            isAllowed={(values) => {
              const { floatValue } = values;
              //warning: props.min will not work, only one digit is allowed
              if(props.min) console.warn("Warning: props.min will detected. Use validator instead.");
              if(props.min&&floatValue<props.min) return false; //props.min compatibility from inputNumber
              if(props.max&&floatValue>props.max) return false; //props.max compatibility from inputNumber
              
              return true;
            }}
            customInput={Input}
            style={{ ...props.style, width: "100%" }} disabled={props.disabled}
             //className={props.hideStep ? "hideStep" : undefined}
        />
  );
};

export default Money;

//backward compatibility. Use method from Helpers instead
export const formatter =currency=> currencyFormatter(currency);
