import { Button, Card, Dropdown, message, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined, EditOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { exe } from '../../Lib/Dal';
import CardEdit from './CardEdit';
import ColEdit from './ColEdit';

// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `id ${k + offset}`
    }));

const TaskBoard = (props) => {
    const [state, setState] = useState([{id:1,name:"col1",Cards:[]},{id:1,name:"col2",Cards:[]}]);
    const [loading, setLoading] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedCol, setSelectedCol] = useState(null);

    useEffect(() => {
        if(props.taskId) loadBoard();
    }, [props.taskId]);

    const reorder = (list, startIndex, endIndex) => {
        const result = JSON.parse(JSON.stringify(list));
        const [removed] = result.Cards.splice(startIndex, 1);
        
        //updating order of the moved card using an an in-between ordering strategy
        //1. Identify the order of the card immediately before and after the new position.
        const before = result.Cards[endIndex - 1];
        const after = result.Cards[endIndex];
        //2. Assign a new order that’s the midpoint between these two values.
        //if its dragged to the end of the list then the order is the last card order + 1
        if(!after && !before) removed.order = 1000000; //first card
        else if(!after) removed.order = ((before?.order || 0) + 1000000) / 2; //end of list
        else if(!before) removed.order = ((after?.order || 1000000) - 0) / 2; //start of list
        else removed.order = (before.order + after.order) / 2;

        result.Cards.splice(endIndex, 0, removed);
         //saving removed to DB
         exe("RepoProjectTaskCard",{operation:"UPDATE",entity:removed}).then((r) => {
            if(r.ok){
            }else message.error(r.msg);
        });

        return result;
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = JSON.parse(JSON.stringify(source));
        const destClone = JSON.parse(JSON.stringify(destination));

        const [removed] = sourceClone.Cards.splice(droppableSource.index, 1);
        removed.projectTaskColId = destClone.id;
        
        //updating order of the moved card using an an in-between ordering strategy
        //1. Identify the order of the card immediately before and after the new position.
        const before = destClone.Cards[droppableDestination.index - 1];
        const after = destClone.Cards[droppableDestination.index ];
        //2. Assign a new order that’s the midpoint between these two values.
        //if its dragged to the end of the list then the order is the last card order + 1
        if(!after && !before) removed.order = 1000000; //first card
        else if(!after) removed.order = ((before?.order || 0) + 1000000) / 2; //end of list
        else if(!before) removed.order = ((after?.order || 1000000) - 0) / 2; //start of list
        else removed.order = (before.order + after.order) / 2;
        
        destClone.Cards.splice(droppableDestination.index, 0, removed);

        //saving removed to DB
        exe("RepoProjectTaskCard",{operation:"UPDATE",entity:removed}).then((r) => {
            if(r.ok){
            }else message.error(r.msg);
        });

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };
    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "#E8F5E9" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#E3F2FD" : "rgb(248, 249, 251)",
        padding: grid,
        width: 250
    });
    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            const newState = [...state];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];

            setState(newState);
        }
    }
    
    const loadBoard = () => {
        setLoading(true);
        exe("RepoProjectTaskCol",{operation:"GET",filter:`projectTaskId=${props.taskId}`,include:["Cards"]}).then((r) => {
            setLoading(false);
            if(r.ok){
                //creating an array of columns
                setState(r.outData);
            }else message.error(r.msg);
        }); }

        const onAddColumn = () => {

            const newColumn = {
                projectId: props.projectId,
                projectTaskId: props.taskId,
                name: "New Column",
                order: state.length * 100000,
            }
            exe("RepoProjectTaskCol",{operation:"ADD",entity:newColumn}).then((r) => {
                if(r.ok){
                    message.success(r.msg);
                    loadBoard();
    
                }else message.error(r.msg);
            });
        }
    const onNewCard = (colId) => {

        //getting order of the new card using an an in-between ordering strategy. dont want to use zero as order, getting the first card order and dividing by 2
        const order = state.find(c => c.id === colId).Cards[0]?.order / 2 || 1000000;


        const newCard = {
            projectId: props.projectId,
            projectTaskId: props.taskId,
            projectTaskColId: colId,
            name: "New Card",
            order: order,
        }
        exe("RepoProjectTaskCard",{operation:"ADD",entity:newCard}).then((r) => {
            if(r.ok){
                message.success(r.msg);
                loadBoard(); //temp should only load column 

            }else message.error(r.msg);
        });
    }

    const onSaveCard = (card) => {
        const newState = [...state];
        const col = newState.find(c => c.id === card.projectTaskColId);
        const cardIndex = col.Cards.findIndex(c => c.id === card.id);
        col.Cards[cardIndex] = card;
        setState(newState);
        setSelectedCard();
    }
    const onDeleteCard = (card) => {
        //removing from state
        const newState = [...state];
        const col = newState.find(c => c.id === card.projectTaskColId);
        col.Cards = col.Cards.filter(c => c.id !== card.id);
        setState(newState);
        setSelectedCard();
    }
    const onSaveCol = (col) => {
        loadBoard(); //temp should only load column
        setSelectedCol();
    }
    const onDeleteCol = (col) => {
        //removing from state
        const newState = state.filter(c => c.id !== col.id);
        setState(newState);
        setSelectedCol();
    }
    const moveCol = (col, dir) => {
        // Sort the state array based on the order property
        const sortedState = [...state].sort((a, b) => a.order - b.order);
        const colIndex = sortedState.findIndex(c => c.id === col.id);
        if (colIndex === -1) return; // Column not found in state
    
        let newOrder;
    
        if (dir === -1) { // Move up
            if (colIndex === 0) return; // Already at the top
            const prevCol = sortedState[colIndex - 1];
            const secondPrevCol = sortedState[colIndex - 2] || { order: prevCol.order - 1 };
            newOrder = (prevCol.order + secondPrevCol.order) / 2;
        } else { // Move down
            if (colIndex === sortedState.length - 1) return; // Already at the bottom
            const nextCol = sortedState[colIndex + 1];
            const secondNextCol = sortedState[colIndex + 2] || { order: nextCol.order + 1 };
            newOrder = (nextCol.order + secondNextCol.order) / 2;
        }
    
        col.order = newOrder;
        console.log(col.order);
    
        exe("RepoProjectTaskCol", { operation: "UPDATE", entity: col }).then((r) => {
            if (r.ok) {
                loadBoard();
            } else {
                message.error(r.msg);
            }
        });
    }
    const colMenu= [
        {
          key: 'edit',
          label: 'Edit',
          icon: <EditOutlined />,
        },
        {
            key: 'left',
            label: 'Move Left',
            icon: <LeftOutlined />,
          },
          {
            key: 'right',
            label: 'Move Right',
            icon: <RightOutlined />,
          }
      ];
      const onColMenuClick = (obj,col) => {
        switch (obj.key) {
            case 'edit':
                setSelectedCol(col);
                break;
            case 'left':
                moveCol(col,-1);
                break;
            case 'right':
                moveCol(col,1);
                break;
            default:
                break;
      }
    }
    return (
        <div>
            <div>
                <Button type={"link"} icon={<LeftOutlined />} onClick={() => props.onBack()}>Back</Button>
                <Button type={"link"} onClick={onAddColumn}>New Column</Button>
                <Spin spinning={loading} />
            </div>
            <div style={{ display: "flex" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    {state
                    .sort((a, b) => a.order - b.order)
                    .map((col, ind) => (
                        <Droppable key={ind} droppableId={`${ind}`}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <h4>{col.name}</h4>
                                        <Space>
                                            <Button style={{ padding: 0}} type="link" onClick={() => onNewCard(col.id)} icon={<PlusOutlined />} />
                                            <Dropdown menu={{items:colMenu,onClick:(obj)=>onColMenuClick(obj,col)}}>
                                                <a onClick={(e) => e.preventDefault()}><DownOutlined /></a>
                                            </Dropdown>
                                        </Space>
                                    </div>
                                    {col.Cards
                                    .sort((a, b) => a.order - b.order)
                                    .map((card, index) => (
                                        <Draggable
                                            key={card.id}
                                            draggableId={`card-${card.id}`}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    onMouseEnter={(e) => (e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)")}
                                                    onMouseLeave={(e) => (e.currentTarget.style.boxShadow = "")}
                                                >
                                                     {/* <Card
                                                        bodyStyle={{  fontSize: "0.7vw",padding:2 }}
                                                        hoverable
                                                        actions={[
                                                            <CaretRightOutlined key="play" onClick={() => setSelectedCard(card)} />,
                                                            // <Icon type="edit" key="edit" />,
                                                            // <Icon type="ellipsis" key="ellipsis" />,
                                                        ]}>
                                                        <Card.Meta title={card.name} description={card.content} />
                                                        </Card> */}
                                                    <div onClick={() => setSelectedCard(card)}
                                                 
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-around",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        {card.name}
                                                       
                                                       
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
            </div>
            <CardEdit selected={selectedCard} visible={selectedCard} onCancel={()=>setSelectedCard()} onSave={onSaveCard} onDelete={onDeleteCard} />
            <ColEdit selected={selectedCol} visible={selectedCol} onCancel={()=>setSelectedCol()} onSave={onSaveCol} onDelete={onDeleteCol} />
        </div>
    );
}

export default TaskBoard;