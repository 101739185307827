import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Popconfirm, Row, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { SaveOutlined, LeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import DatePickerW from "../Shared/DatePickerW";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import Percentage from "../Shared/Percentage";
import RelationshipSelect from "../Contact/RelationsipSelect";

const PensionMemberBeneficiary = (props) => {
    const [loading,setLoading]=useState(false);
    const [t] = useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        if(props.value){
            if(props.value.id==0){
                form.resetFields();
                form.setFieldValue("pensionMemberId",props.pensionMemberId);
            }else form.setFieldsValue(props.value);
        }
    }
    ,[props.value])

    const save=()=>{
        form.validateFields().then(values=>{
            console.log(values);
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoPensionBeneficiary",{operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onSave(values);
                    props.onCancel();

                }else{
                    message.error(r.msg);
                }
            })

        })
    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoPensionBeneficiary",{operation:"DELETE",entity:props.value}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                props.onSave(props.value);
                props.onCancel();
            }else message.error(r.msg);
        })
    }

    return <div>
        <Button type="link" icon={<LeftOutlined />} onClick={props.onCancel}>{t("Back")}</Button>
        <Button type="link" icon={<SaveOutlined />} onClick={save} loading={loading}>{t("Save")}</Button>
        <Popconfirm  title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={!props.value||!props.value.id}><Button type="link" icon={<DeleteOutlined />}  disabled={!props.value||!props.value.id} >{t("Delete")}</Button></Popconfirm> 
        <Form form={form} layout="vertical">
            <Form.Item label={t("id")} name={"id"} hidden><Input disabled/></Form.Item>
            <Form.Item label={t("pensionMemberId")} name={"pensionMemberId"} hidden ><Input disabled/></Form.Item>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label={t("Contact")} name={"contactId"} rules={[{required:true}]}><SimpleContactSelect allowNew /></Form.Item>
                    <Form.Item label={t("Relationship")} name={"relationshipId"} rules={[{required:true}]}><RelationshipSelect principalType={"PERSON"} /></Form.Item>
                    <Form.Item label={t("Split")} name={"percentage"} rules={[{required:true}]}><Percentage/></Form.Item>
                </Col>
            </Row>

        </Form>
    </div>
}
export default PensionMemberBeneficiary;