import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Checkbox,
  Divider,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {exe, safeGet, safeGetRaw} from "../../Lib/Dal";
import { getConfigProfile } from "../../Lib/Helpers";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import ContractAccountSelect from "./ContractAccountSelect";

const ParticipantDetail = (props) => {
  const field = props.form.getFieldDecorator;
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation();
  const [contactRoles, setContactRoles] = useState([]);
  const [contractBehaviour,setContractBehaviour]=useState();

  useEffect(() => {
    if (props.selected) {
      props.form.setFieldsValue(props.selected);
    }
  }, [props.selected]);

  useEffect(() => {
    getConfigProfile().then((profile) => {
      setContactRoles(safeGet(["Reinsurance", "contactRoles"], window.global.configProfile, []));
    });
  }, []);
  
  useEffect(()=>{
    if(props.visible){
      loadContractBehaviour();
    }
  },[props.visible])
  
  
  const loadContractBehaviour=()=>{
    setLoading(true);
    exe("DoQuery",{sql:`SELECT configJson from Contract WHERE id=${props.contractId}`}).then(r=>{
      setLoading(false);
      if(r.ok){
        if(r.outData && r.total >= 1) setContractBehaviour(JSON.parse(r.outData[0].configJson));
      }else message.error(r.msg);
    })
  }

  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("RepoParticipant", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          props.onSave && props.onSave(values);
          props.form.resetFields();
        } else {
          if (values.id&&r.changeRequest) {
            const before = {id:values.id};
            const after = {id:values.id};
            r.unauthorized.forEach((element) => {
              before[element] = r.outData[0][element];
              after[element] = r.data.entity[element];
            });
            props.form.setFieldsValue(before); //reverting unauth changes
            Modal.confirm({
              title: t("Restricted change"),
              content: r.msg,
              onOk() {
                const change = {
                  contractId: r.outData[0].contractId,
                  jBefore: JSON.stringify(before),
                  JAfter: JSON.stringify(after),
                  operation: r.changeOperation,
                };
                exe("AddContractChange", change).then((r) => {
                  if (r.ok) {
                    message.success(r.msg);
                    props.reloadContract&&props.reloadContract();
                    onCancel();
                  } else {
                    message.error(r.msg);
                  }
                });
              },
            });
          } else {
            message.error(r.msg);
          }
        }
      });
    });
  };
  const onCancel = () => {
    props.form.resetFields();
    props.onCancel && props.onCancel();
  };
  
  const onSelectContact=(contact)=>{
    if(commission){
      setLoading(true);
      const context={con:contact};
      exe("ExeFormula",{formula:commission,context:JSON.stringify(context)}).then(r=>{
        setLoading(false);
        if(r.ok){
          if(r.outData&&r.outData.length>=1) props.form.setFieldsValue({commissionRate:r.outData[0]})
        }else message.error(r.msg);
      })
    }
    if(tax){
      setLoading(true);
      const context={con:contact};
      exe("ExeFormula",{formula:tax,context:JSON.stringify(context)}).then(r=>{
        setLoading(false);
        if(r.ok){
          if(r.outData&&r.outData.length>=1) props.form.setFieldsValue({taxRate:r.outData[0]})
        }else message.error(r.msg);
      })
    }
    const broker=safeGetRaw(["Participants","broker"],contractBehaviour,undefined);
    if(broker){
        setLoading(true);
        const context={con:contact};
        exe("ExeFormula",{formula:broker,context:JSON.stringify(context)}).then(r=>{
            setLoading(false);
            if(r.ok){
            if(r.outData&&r.outData.length>=1) props.form.setFieldsValue({brokerId:r.outData[0]})
            }else message.error(r.msg);
        })
        }
    
  }
  
  const commissionEditable=safeGetRaw(["Participants","commissionEditable"],contractBehaviour,true);
  const commission=safeGetRaw(["Participants","commission"],contractBehaviour,undefined);
  const taxEditable=safeGetRaw(["Participants","taxEditable"],contractBehaviour,true);
  const tax=safeGetRaw(["Participants","tax"],contractBehaviour,undefined);
  const brokerEditable=safeGetRaw(["Participants","brokerEditable"],contractBehaviour,true);
  const values = props.form.getFieldsValue();
  return (
    <div>
      <Modal title={t("Participant")} visible={props.visible} onCancel={onCancel} onOk={onSubmit} okButtonProps={{ loading: loading }}>
        {field("id")}
        {field("contractId", { initialValue: props.contractId })}
        <Form.Item label={t("Participant")}>
          {field("contactId", { rules: [{ required: true }] })(
            <SimpleContactSelect onSelectContact={onSelectContact} allowNew
              filter={
                contactRoles.length == 0
                  ? undefined
                  : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `
              }
            />
          )}
        </Form.Item>
        <Form.Item label={t("Line ID")}>{field("lineId", { rules: [{ required: true }] })(<Input />)}</Form.Item>
        <Form.Item label={t("Split %")}>{field("split", { rules: [{ required: true }] })(<InputNumber />)}</Form.Item>
        <Form.Item label={t("Commission %")}>{field("commissionRate", { rules: [{ required: true }] })(<InputNumber disabled={!commissionEditable} />)}</Form.Item>
        <Form.Item label={t("Withholding tax %")}>{field("taxRate", { rules: [{ required: true }] })(<InputNumber disabled={!taxEditable} />)}</Form.Item>
        <Form.Item label={t("Account")}>
          {field("accountId", { rules: [{ required: false }] })(<ContractAccountSelect contactId={values.contactId} contractId={props.contractId} />)}
        </Form.Item>
        <Form.Item label={t("Broker")}>
          {field("brokerId", { rules: [{ required: false }] })(
              <SimpleContactSelect disabled={!brokerEditable} 
                  filter={
                    contactRoles.length == 0
                        ? undefined
                        : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                  }
              />
          )}
        </Form.Item>
        <Form.Item label={t("Notes")}>{field("notes")(<Input.TextArea autoSize={{minRows: 2}} placeholder={t("Additional notes or observations")} />)}</Form.Item>

     {/*   <Form.Item label={t("Is Broker")}>
          {field("isBroker", { valuePropName:"checked" })(<Checkbox />)}
        </Form.Item>*/}
       
      </Modal>
    </div>
  );
};

export default Form.create()(ParticipantDetail);
