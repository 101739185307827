import React, { Component } from "react";

import {
  ApartmentOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  GiftOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  RobotOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';

import {
  Table,
  Empty,
  Button,
  Modal,
  Badge,
  Dropdown,
  Tooltip,
  Popover,
  Tag,
  Divider,
  message,
  Typography,
} from "antd";
import { equal, safeGet } from "../../Lib/Dal";
import CoverageSelect from "./CoverageSelect";
import CoverageDetail from "./CoverageDetail";
import Paragraph from "antd/lib/typography/Paragraph";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import {formatMoney, formatPercent, getModalPremium} from "../../Lib/Helpers";
import Column from "antd/lib/table/Column";
import { withTranslation } from "react-i18next";
import BenefitGroupSelect from "./BenefitGroupSelect";
import BenefitDetail from "./BenefitDetail";
import BenefitEditor from "./BenefitEditor";
const CustomExpandIcon = (props) => {
  if (!props.record.Benefits || props.record.Benefits.length == 0) return null;
  if (props.expanded) {
    return (
      <a onClick={(e) => props.onExpand(props.record, e)}>
        <MinusCircleOutlined />
      </a>
    );
  } else {
    return (
      <a onClick={(e) => props.onExpand(props.record, e)}>
        <PlusCircleOutlined />
      </a>
    );
  }
};
class Coverages extends Component {
  state = {
    coverageDetailVisible: false,
    selectedCoverages: [],
    coverages: [],
    benefitDetail: undefined,
    benefitEditorVisible: false
  };
  columns = [
    {
      title: this.props.t("Code"),
      dataIndex: "code",
      width: 120,
      render: (t, r) => (
        <div style={{whiteSpace:"nowrap"}}>
          <Popover
            content={
              <div style={{ width: 250 }}>
                <Paragraph strong>{r.name}</Paragraph>
                <Paragraph>{r.description}</Paragraph>
              </div>
            }
            title={t}>
            <InfoCircleOutlined
              style={{ marginRight: 5, cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }} />
          </Popover>
          <Text code>{t}</Text>
        </div>
      ),
    },
    {
      title: this.props.t("Name"),
      dataIndex: "name",
      width: 250,
      render: (t, r) => (
        <div>
          <Paragraph strong ellipsis={{ rows: 2 }}>
            {t}
          </Paragraph>
        </div>
      ),
    },
    {
      title: this.props.t("Indicators"),
      align: "left",
      render: (t, r) => (
        <div style={{ display: "flex" }}>
          {r.basic && <Tag>{this.props.t("Basic")}</Tag>}
          {(r.start || r.end) && (
            <div>
              <Popover
                content={
                  <div style={{ width: 250 }}>
                    {r.start && (
                      <div>
                        <Text strong>{this.props.t("Start")}:</Text>
                        <Text>{moment(r.start).format("YYYY-MM-DD")}</Text>
                      </div>
                    )}
                    {r.end && (
                      <div>
                        <Text strong>{this.props.t("End")}:</Text>
                        <Text>{moment(r.end).format("YYYY-MM-DD")}</Text>
                      </div>
                    )}
                  </div>
                }
                title={this.props.t("Independent validity dates")}>
                <ClockCircleOutlined
                  style={{ marginRight: 5, cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }} />
              </Popover>
            </div>
          )}
          {r.parent && (
            <div>
              <Popover
                content={
                  <div style={{ width: 250 }}>
                    <div>
                      <Text strong>{this.props.t("Parent")}:</Text>
                      <Text>{r.parent}</Text>
                    </div>
                  </div>
                }
                title={this.props.t("Subcoverage")}>
                <ApartmentOutlined
                  style={{ marginRight: 5, cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }} />
              </Popover>
            </div>
          )}
          {r.internalBonus && (
            <div>
              <Popover content={this.props.t("Bonus Coverage")}>
                <GiftOutlined
                  style={{ marginRight: 5, cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }} />
              </Popover>
            </div>
          )}
          {r.manualPremium && (
            <div>
              <Popover content={this.props.t("Manual Premium")}>
                <UserOutlined
                  style={{ marginRight: 5, cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }} />
              </Popover>
            </div>
          )}
          {r.manualLimit && (
            <div>
              <Popover content={this.props.t("Manual Sum Assured")}>
                <UserAddOutlined
                  style={{ marginRight: 5, cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }} />
              </Popover>
            </div>
          )}
          {r.isInternal && (
            <div>
              <Popover content={this.props.t("Internal Coverage")}>
                <RobotOutlined
                  style={{ marginRight: 5, cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }} />
              </Popover>
            </div>
          )}
        </div>
      ),
    },
    {
      title: this.props.t("Sum Assured"),
      dataIndex: "limit",
      render: (t) => formatMoney(t,this.props.currency)
    },
    {
      title: this.props.t("Base Premium"),
      dataIndex: "basePremium",
      render: (t) => formatMoney(t,this.props.currency)
    },
    {
      title: this.props.t("Loading"),
      dataIndex: "loading",
      render: (v,r) =>r.loadingInsuredSum? `${v}%/${r.loadingInsuredSum}%`:`${v}%`,
    },
    {
      title: this.props.t("Extra Premium"),
      dataIndex: "extraPremium",
      render: (t) => formatMoney(t,this.props.currency)
    },
    {
      title: this.props.t("Premium"),
      dataIndex: "premium",
      render: (t) => formatMoney(t,this.props.currency)
    },
    {
      title: this.props.t("Modal Premium"),
      dataIndex: "premium",
      key: "modalPremium",
      render: (t) => formatMoney(getModalPremium(this.props.policy ? this.props.policy.periodicity : null, t),this.props.currency),
    },
    {
      title: this.props.t("Deductible"),
      dataIndex: "deductible",
      render: (t) => formatMoney(t,this.props.currency)
    },
    {
      title: this.props.t("Action"),
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button icon={<EditOutlined />} type="link" onClick={() => this.setState({ coverageDetailVisible: true, updatedCoverage: record })}>
            {this.props.readOnly ? this.props.t("Details") : this.props.t("Edit")}
          </Button>
          <Divider type="vertical" />
          <Button disabled={this.props.readOnly || this.isMandatory(record)||this.props.policy&&this.props.policy.policyType=="C"&&this.props.policy.groupCoverageType&&this.props.policy.groupCoverageType.includes("SAME")} icon={<DeleteOutlined />} type="link" onClick={() => this.onRemoveCoverage(record)}>
            {this.props.t("Remove")}
          </Button>
        </div>
      ),
    },
  ];
  componentDidMount() {
    const selectedCoverages = this.props.coverages && this.props.coverages.map((p) => p.code); //allready present in policy
    const covs = this.getCovs(this.props.coverages); //assure mandaotry covs are in
    this.setState({ coverages: covs, selectedCoverages });
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.coverages, prevProps.coverages) || !equal(this.props.config, prevProps.config)) {
      const selectedCoverages = this.props.coverages && this.props.coverages.map((p) => p.code); //allready present in policy
      const covs = this.getCovs(this.props.coverages); //assure mandaotry covs are in
      this.setState({ coverages: covs, selectedCoverages});
    }
  }

  getCovs(covs) {
      if(!covs) return [];
    if (!this.props.config && !this.props.readOnly) return []; //no product loaded
    if (covs.length == 0) {
      //asure at least mandatory if no covs
      const pressentAndMandatory = [
        ...this.props.config.Coverages.filter((p) => p.mandatory),
        ...covs,
        ...this.props.config.Coverages.filter((p) => p.includedByDefault),
      ];
      const uniqueSet = new Set(pressentAndMandatory);
      const present = [...uniqueSet];
      this.props.onChange(present);
      return present;
    } else {
      return covs;
    }
  }

  onUpdateCoverage(v) {
    const covs = this.state.coverages;
    const index = covs.findIndex((p) => p.code == v.code);
    covs[index] = v;
    this.setState(
      {
        coverages: covs,
        coverageDetailVisible: false,
      },
      () => this.props.onChange(this.state.coverages)
    );
  }
  onRemoveCoverage(cov) {
    //if the removed cov is parent, remove all children
    //finding all children
    const children = this.props.config.Coverages.filter((p) => p.parent == cov.code);
    const newCovs = this.state.coverages.filter((p) => p.code !== cov.code && !children.map((p) => p.code).includes(p.code));
    this.setState(
      {
        coverages: newCovs,
      },
      () => this.props.onChange(this.state.coverages)
    );
  }
  isPlanCoverage(cov) {
    if (this.props.policy && this.props.policy.plan && this.props.config) {
      const plan = this.props.config.Plans.find((p) => p.code == this.props.policy.plan);
      if(!plan) return false;
      return plan.coverages &&(plan.closed||plan.basic==cov||(plan.mandatoryCoverages&&plan.mandatoryCoverages.includes(cov)))&& plan.coverages.includes(cov);
    } else {
      return false;
    }
  }
  isMandatory(cov) {
    if (this.props.config) {
      const productCov = this.props.config.Coverages.find((p) => p.code === cov.code);
      if (!productCov) {
        if (this.props.config.Subcoverages) {
          const productSubcov = this.props.config.Subcoverages.find((p) => p.code === cov.code);
          if (!productSubcov) {
            message.warning(`${this.props.t("Coverage")} ${cov.code} ${this.props.t("is no longer present in the product config")}`);
          } else {
            return productSubcov.mandatory;
          }
        } else {
          message.warning(`${this.props.t("Coverage")} ${cov.code} ${this.props.t("is no longer present in the product config")}`);
        }
      } else {
        if (this.isPlanCoverage(cov.code)) return true;
        return productCov.mandatory;
      }
    } else {
      return false;
    }
  }
  onSelectedChange() {
    //when user presses ok on coverage selection modal
    const productCovs = this.props.config.Coverages.map((a) => ({ ...a })); //copying array. Otherwise changes ref'd value
    const newCoverages = productCovs.filter((p) => !this.state.coverages.map((p) => p.code).includes(p.code) && this.state.selectedCoverages.includes(p.code));

    this.setState(
      {
        coverages: [...this.state.coverages.filter((p) => this.state.selectedCoverages.includes(p.code)), ...newCoverages],
        coverageSelectVisible: false,
      },
      () => this.props.onChange(this.state.coverages)
    );
  }
  onChangeBenefitGroup(coverageCode, benefitCode, groupCode) {
    const updatedCov = this.state.coverages.find((p) => p.code == coverageCode);
    const updatedBenefit = updatedCov.Benefits.find((p) => p.code == benefitCode);
    updatedBenefit.group = groupCode;
    updatedBenefit.action = "UPDATE";
    
    this.onUpdateCoverage(updatedCov);
  }
  onChangeBenefit(benefit) {
      benefit.action = "UPDATE";
      console.log(benefit, "BENEFIT CHANGE");
      const updatedCov = this.state.coverages.find((p) => p.id == benefit.lifeCoverageId);
      updatedCov.Benefits = updatedCov.Benefits.map((p) => (p.code == benefit.code ? benefit : p));
      this.onUpdateCoverage(updatedCov);
      this.setState({ benefitDetail: undefined });
  }
  onBenefitEditorOk(coverages){
      this.setState({coverages:coverages,benefitEditorVisible:false});
      this.props.onChange(coverages);
  }
checkIfInternalIsHidden(covergeCode){
    const config = this.props.config;
    if(config&&config.Coverages){
        const coverageConfig = config.Coverages.find(p=>p.code==covergeCode);
        if(coverageConfig&&coverageConfig.hidden){
            return true;
        }
    }
    return false;
}
  render() {
    const { t, i18n } = this.props;
    if (!this.props.coverages) return <Empty />;

    return (
      <div style={{ marginTop: 5, marginBottom: 5 }}>
        {!this.props.readOnly && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginBottom: 5 }}
            onClick={() => this.setState({ coverageSelectVisible: true })}
            disabled={this.props.closedPlan||this.props.policy&&this.props.policy.policyType=="C"&&this.props.policy.groupCoverageType&&this.props.policy.groupCoverageType.includes("SAME")}>
            {t("Add")}
          </Button>
        )}
          {this.state.coverages.some(p=>p.Benefits&&p.Benefits.length)&&<Button type={"link"} icon={<LegacyIcon type={"tool"} />} onClick={()=>this.setState({benefitEditorVisible:true})} >{t("Benefit Editor")}</Button>}
        <Modal
          title={t("Optional coverage selection")}
          width={800}
          visible={this.state.coverageSelectVisible}
          onOk={() => this.onSelectedChange()}
          onCancel={() => this.setState({ coverageSelectVisible: false })}>
          <CoverageSelect excluded={this.props.excluded} selected={this.state.selectedCoverages} 
          config={this.props.config} 
          mandatoryCoverages={this.state.coverages.filter(c=>this.isMandatory(c)).map((p) => p.code)}
          onChange={(v) => this.setState({ selectedCoverages: v })} />
        </Modal>

        <Table
          columns={this.props.config && this.props.config.Main.hideDeductible ? this.columns.filter((p) => p.dataIndex !== "deductible") : this.columns}
          scroll={{ x: true }}
          rowKey="code"
          dataSource={this.state.coverages.filter((p) => !(p.isInternal && this.checkIfInternalIsHidden(p.code)))}
          expandIcon={(p) => <CustomExpandIcon {...p} />}
          expandedRowRender={(r) => (
            <Table dataSource={r.Benefits} className={"paginationLeft"}>
              <Column
                title={t("Action")}
                key="actions"
                render={(v, benefit) => (
                  <div>
                    <Button type="link" icon={<EditOutlined />} onClick={() => this.setState({ benefitDetail: benefit })} />
                  </div>
                )}
              />
              <Column title={t("Code")} dataIndex="code" />
              <Column title={t("Benefit")} dataIndex="name" render={(v, r) => <Typography.Text delete={r.covered == 0}>{v}</Typography.Text>} />
              <Column title={t("Description")} dataIndex="description" />

              <Column
                title={t("Group")}
                dataIndex="group"
                render={(v, benefit) => (
                  <BenefitGroupSelect
                    groups={this.props.groups}
                    value={v}
                    onChange={(groupCode) => this.onChangeBenefitGroup(r.code, benefit.code, groupCode)}
                    readOnly={this.props.readOnly}
                  />
                )}
              />

              <Column title={t("Copay")} dataIndex="copay" />
              <Column title={t("Covered")} dataIndex="covered" render={(v) => formatPercent(v)} />
              <Column title={t("Deductible")} dataIndex="deductible" />
              <Column title={t("Annual Deductible")} dataIndex="aDeductible" />
              <Column title={t("Quarter Deductible")} dataIndex="qDeductible" />
              <Column title={t("Monthly Deductible")} dataIndex="mDeductible" />

              <Column title={t("Limit")} dataIndex="limit" />
              <Column title={t("Family Limit")} dataIndex="familyLimit" />
              <Column title={t("Annual Limit")} dataIndex="aLimit" />
              <Column title={t("Quarter Limit")} dataIndex="qLimit" />
              <Column title={t("Monthly Limit")} dataIndex="mLimit" />
              <Column title={t("Lifetime Limit")} dataIndex="lifetimeLimit" />

              <Column title={t("Event Annual Times")} dataIndex="eventaTimes" />
              <Column title={t("Event Quarter Times")} dataIndex="eventqTimes" />
              <Column title={t("Event Monthly Times")} dataIndex="eventmTimes" />
              <Column title={t("Event Daily Times")} dataIndex="eventdTimes" />

              <Column title={t("Out Of Pocket Annual")} dataIndex="outOfPocketAnualLimit" />

              <Column title={t("Waiting Period")} dataIndex="waitingPeriod" />

              <Column title={t("Special Instructions")} dataIndex="notes" />
            </Table>
          )}
          pagination={false}
        />

        <Modal
          title={t("Coverage Detail")}
          width={820}
          bodyStyle={{ paddingTop: 0 }}
          visible={this.state.coverageDetailVisible}
          onCancel={() => this.setState({ coverageDetailVisible: false })}
          destroyOnClose
          footer={null}>
          <CoverageDetail
            value={this.state.updatedCoverage}
            config={this.props.config}
            policy={this.props.policy}
            onSubmit={(v) => this.onUpdateCoverage(v)}
            currency={this.props.currency}
            readOnly={this.props.readOnly}
            claimId={this.props.claimId}
            coverages={this.state.coverages}
          />
        </Modal>
        <BenefitDetail
          visible={this.state.benefitDetail}
          value={this.state.benefitDetail}
          onCancel={() => this.setState({ benefitDetail: undefined })}
          onOk={(v) => this.onChangeBenefit(v)}
          currency={this.props.currency}
          groups={this.props.groups}
          readOnly={this.props.readOnly}
        />
          <BenefitEditor 
              visible={this.state.benefitEditorVisible} 
              coverages={this.state.coverages}
              policy={this.props.policy}
              onCancel={()=>this.setState({benefitEditorVisible:false})}
              groups={this.props.groups}
              onOk={values=>this.onBenefitEditorOk(values)} />
      </div>
    );
  }
}

export default withTranslation()(Coverages);
