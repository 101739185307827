import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, DatePicker, InputNumber, Button, Popconfirm, message, Row, Col, Select, Tag } from 'antd';
import moment from 'moment';
import { FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { exe } from '../../Lib/Dal';

const internalStates = [{value:0, name:"Not Started"},{value:1, name:"In Progress", color:"blue"},{value:2, name:"Completed", color:"green"},{value:3, name:"Cancelled", color:"red"}];


const ColEdit = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
       
        if(props.selected) {
            form.setFieldsValue(props.selected);
        }
    }, [props.visible, props.selected]);


    const handleOk = () => {
        form.validateFields().then((values) => {
            setLoading(true);
            exe('RepoProjectTaskCol',{operation:"UPDATE",entity:values}).then((res) => {
                setLoading(false);
                if(res.ok){
                    props.onSave(values);
                    message.success(res.msg);
                }else {
                    message.error(res.msg);
                }
            });


        }).catch((info) => {
            form.setFieldsValue({ convertToChild: false });
            console.log('Validate Failed:', info);
        });
    };

    const onDeleteCol = (card) => {
        setLoading(true);
        exe("RepoProjectTaskCol",{operation:"DELETE",entity:{id:card.id}}).then((r) => {
            setLoading(false);
            if(r.ok){
                console.log(r.outData);
                props.onDelete(card)
            }else message.error(r.msg);
        });
    }

    return (
        <Modal
            visible={props.visible}
            title={<span><FileOutlined /> Column {props.selected?.id}</span>}
            width={800}
            onCancel={props.onCancel}
            footer={[
                <Button key="cancel" onClick={props.onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} loading={loading}>
                    Save
                </Button>,
            ]}
        >
            <Row>
                <Col span={20}>
                    <Form form={form} layout="vertical">
                        <Form.Item name="id" hidden>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="projectTaskId" hidden>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="order" hidden>
                            <Input type="hidden" />
                        </Form.Item>
                                             
                        <Form.Item name="name" label="Title">
                            <Input />
                        </Form.Item>
                        <Form.Item name="status" label="Status">
                        <Select style={{ width: "100%" }} placeholder={"Internal Status"}>
                        {internalStates.map((p) => (
                        <Select.Option value={p.value} key={p.value}>
                            <Tag color={p.color}>{p.name}</Tag>
                        </Select.Option>
                        ))}
                    </Select>
                        </Form.Item>
                       
                    </Form>
                </Col>
                <Col span={4}>
                     <Button type={"link"} icon={<DeleteOutlined />} onClick={() => onDeleteCol(props.selected) }>Delete</Button>
                </Col>
            </Row>
           
         
        </Modal>
    );
};

export default ColEdit;
