import React, { useEffect, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { RedoOutlined, SaveOutlined,ThunderboltOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Tabs,
  Tag,
  Row,
  Col,
  Button,
  Switch,
  message,
  InputNumber,
  Space,
} from "antd";
import Money from "../Shared/Money";
import Currency from "../Shared/Currency";
import ContactSelect from "../Contact/ContactSelect";
import AccountSelect from "../Shared/AccountSelect";
import PolicySelect from "../Health/PolicySelect";
import { exe } from "../../Lib/Dal";
import ClaimSelect from "../Shared/ClaimSelect";
import FrequencySelect from "../Shared/FrequencySelect";
import AnnuityPayments from "./AnnuityPayments";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";
import AnnuityProductSelect from "./AnnuityProductSelect";

const Annuity = (props) => {
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation();

  const field = props.form.getFieldDecorator;
  field("Beneficiary"); //registering field (not rendered)
  field("id"); //registering field (not rendered)
  field("Policy"); //registering field (not rendered)

  const annuityId = props.form.getFieldValue("id") || 0;
  const entityState = "PENDING"; //temp
  const currency = props.form.getFieldValue("currency");
  const beneficiary = props.form.getFieldValue("Beneficiary") || {};
  const policy = props.form.getFieldValue("Policy") || {};

  useEffect(() => {
    if (props.match.params.annuityId !== "0") {
      load(props.match.params.annuityId);
    } else {
      props.form.resetFields();
    }
  }, [props.match.params.annuityId]);

  const load = (id) => {
    setLoading(true);
    exe("RepoAnnuity", { operation: "GET", filter: "id=" + id, include: ["Beneficiary", "Policy", "Payments"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        if (r.outData.length == 0) {
          message.error(`Record ${id} not found`);
          return;
        }
        props.form.setFieldsValue(r.outData[0]);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (values.active && !values.start) {
        message.error("If active, annuity must have a start date");
        return;
      }
       //yaml validation
    //    try {
    //     const result = yaml.safeLoad(values.config);
    //     values.configJson = JSON.stringify(result);
    //   } catch (error) {
    //       notification["error"]({
    //           message: error.reason,
    //           description: error.message,
    //       });
    //     return;
    // }
      setLoading(true);
      exe("RepoAnnuity", { operation: values.id > 0 ? "UPDATE" : "ADD", entity: values }, undefined, true).then((r) => {
        setLoading(false);
        props.form.setFieldsValue(r.outData[0]);
      });
    });
  };
  const onReload = () => {
    load(props.match.params.annuityId);
  };
  const onCompute = () => {
    setLoading(true);
    exe("DoAnnuityPayments", { annuityId: annuityId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load(props.match.params.annuityId);
      } else {
        message.error(r.msg);
      }
    }
    );
  }

  return (
    <div>
      <DefaultPage
        routes={{
          routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Annuities"), path: "Annuities" },
            { breadcrumbName: t("Annuity"), path: "" },
          ],
        }}
        loading={loading}
        title={
          annuityId == 0 ? (
            t("New Annuity")
          ) : (
            <span>
              {`${t("Annuity")} ${annuityId}  `}
              <Tag>{entityState}</Tag>
            </span>
          )
        }
        extra={
          <Space>
            <Button type="primary" icon={<SaveOutlined />} onClick={() => onSave()}>
              {t("Save")}
            </Button>
            <Button  icon={<RedoOutlined />} onClick={() => onReload()}>
              {t("Reload")}
            </Button>
              <Button icon={<ThunderboltOutlined />} onClick={() => onCompute()}>
              {t("Compute")}
            </Button>
          </Space>
        }
        icon="pull-request"></DefaultPage>
      <Tabs>
        <Tabs.TabPane tab={t("Description")} key="description">
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={8}>
                {field("configJson")}
                <Form.Item label={t("Beneficiary")}>
                  {field("beneficiaryId", { rules: [{ required: true }] })(<ContactSelect display={beneficiary.FullName} />)}
                </Form.Item>
                <Form.Item label={t("Product")}>{field("productCode")(<AnnuityProductSelect />)}</Form.Item>
                <Form.Item label={t("Policy")}>{field("lifePolicyId")(<PolicySelect display={policy.code} />)}</Form.Item>
                <Form.Item label={t("Claim")}>{field("claimId")(<ClaimSelect />)}</Form.Item>
                <Form.Item label={t("Source Account")}>{field("sourceAccountId", { rules: [{ required: true }] })(<AccountSelect />)}</Form.Item>
                <Form.Item label={t("Destination Account")}>{field("destinationAccountId", { rules: [{ required: true }] })(<AccountSelect />)}</Form.Item>
              </Col>
              <Col span={8}>
                <div style={{ display: "flex" }}>
                  <Form.Item style={{ marginRight: 5 }} label={t("Amount")}>
                    {field("amount")(<Money currency={currency} />)}
                  </Form.Item>
                  <Form.Item label={t("Currency")}>{field("currency")(<Currency />)}</Form.Item>
                </div>
                <Form.Item label={t("Limit")}>{field("limit")(<Money currency={currency} />)}</Form.Item>

                <Form.Item label={t("Frequency")}>{field("frequency", { rules: [{ required: true }] })(<FrequencySelect />)}</Form.Item>
                <Form.Item label={t("Installments")}>{field("installments")(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Payments Made")}>{field("paymentsMade")(<InputNumber disabled />)}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Active")}>{field("active", { valuePropName: "checked" })(<Switch />)}</Form.Item>
                {/* <Form.Item label="ID">{field("id")(<Input disabled />)}</Form.Item> */}

                <div style={{ display: "flex" }}>
                  <Form.Item style={{ marginRight: 5 }} label={t("Start")}>
                    {field("start", {
                    })(<DatePickerW />)}
                  </Form.Item>
                  <Form.Item label={t("End")}>
                    {field("end", {
                    })(<DatePickerW />)}
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Payments")} key="payments">
          {field("Payments")(<AnnuityPayments />)}
        </Tabs.TabPane>
        {/* <Tabs.TabPane forceRender
                tab={
                  <span>
                    <CodeOutlined />
                    {t("Behaviour")}
                  </span>
                }
                key="behaviour">
                <Title style={{ marginTop: 5 }} level={4}>
                  {t("Advanced Annuity Behaviour")}
                </Title>
                {field("config")(<CodeEditorW formField />)}
              </Tabs.TabPane> */}
      </Tabs>
    </div>
  );
};

export default Form.create()(Annuity);
