import React, { useEffect, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { SearchOutlined, ThunderboltOutlined, CalendarOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Tabs,
  Tag,
  Input,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Button,
  Switch,
  message,
  Divider,
  Dropdown,
  Space,
  Modal,
  Form
} from "antd";
import Money from "../Shared/Money";
import Currency from "../Shared/Currency";
import ContactSelect from "../Contact/ContactSelect";
import AccountSelect from "../Shared/AccountSelect";
import PolicySelect from "../Health/PolicySelect";
import { exe, objectToFilter } from "../../Lib/Dal";
import ButtonGroup from "antd/lib/button/button-group";
import Filter from "../Health/ClaimListFilter";
import MoneyLabel from "../Shared/MoneyLabel";
import { useTranslation } from "react-i18next";
import AnnuityFilter from "./AnnuityFilter";
import DatePickerW from "../Shared/DatePickerW";
import FrequencySelect from "../Shared/FrequencySelect";

const AnnuityList = (props) => {
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showComputeByDate, setShowComputeByDate] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    load();
  }, []);

  const load = (filter = "") => {
    exe("RepoAnnuity", {
      operation: "GET",
      filter: filter,
      include: ["Beneficiary", "DestinationAccount"],
    }).then((r) => {
      if (r.ok) {
        setData(r.outData.reverse());
      } else {
        message.error(r.msg);
      }
    });
  };
  const computeNow = () => {
    setLoading(true);
    exe("PutMessage", {
      batch: "Annuity",
      value: `{cmd:"DoAnnuityPayments"}`,
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success("Operation successfully sent to event bus");
      } else message.error(r.msg);
    });
  };
  const onFilter = (values) => {
    const sqFilter = objectToFilter(values);
    load(sqFilter);
  };
  const handleMenuClick = (e) => {
    if(e.key==='dateRange'){
      setShowComputeByDate(true);
    }
  }
  const items = [
    {
      label: t("Compute Date Range"),
      key: 'dateRange',
      icon: <CalendarOutlined />,
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const handleComputeByDate = () => {
    form.validateFields().then((values) => {
      console.log(values);
      //at least one date
      if (!values.start && !values.end) {
        message.error(t("You must provide at least one date"));
        return;
      }
      
      setLoading(true);
      exe("PutMessage", {
        batch: "Annuity",
        value: `{cmd:"DoAnnuityPayments",data:${JSON.stringify(values)}}`,
      }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success("Operation successfully sent to event bus");
        } else message.error(r.msg);
      });

      setShowComputeByDate(false);
    });
  }

  return (
    <div>
      <DefaultPage
        routes={{
          routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Annuities"), path: "Annuities" },
            { breadcrumbName: t("Annuity"), path: "" },
          ],
        }}
        title={t("Annuities")}
        icon="search"
        extra={
          <div>
            <ButtonGroup>
              {/* <Button icon={<ThunderboltOutlined />} onClick={computeNow}>
                {t("Compute")}
              </Button> */}
              <Space>
                <Dropdown.Button menu={menuProps} onClick={computeNow}  >
                  <ThunderboltOutlined />
                  {t("Compute")}
                </Dropdown.Button>
                <Button type="primary" icon={<SearchOutlined />} onClick={() => setShowFilter(true)}>
                  {t("Filter")}
                </Button>
              </Space>
            </ButtonGroup>
          </div>
        }>
        <Table dataSource={data} rowKey="id" loading={loading}>
          <Table.Column title={t("Id")} dataIndex="id" />
          <Table.Column title={t("Name")} dataIndex="Beneficiary" render={(v, r) => v.FullName} />
          <Table.Column title={t("Destination")} dataIndex="DestinationAccount" render={(v, r) => v.accNo} />
          <Table.Column title={t("Amount")} dataIndex="amount" render={(v, r) => <MoneyLabel currency={r.currency} value={v} />} />
          <Table.Column title={t("Status")} dataIndex="active" render={(v, r) => <Checkbox checked={v} />} />
          <Table.Column
            title={t("Action")}
            render={(text, record) => (
              <span>
                <a href={"#/annuity/" + record.id}>{t("Open")}</a>
                <Divider type="vertical" />
                <a href={"#/contact/" + record.beneficiaryId}>
                  <UserOutlined></UserOutlined>
                </a>
              </span>
            )}
          />
        </Table>
        <AnnuityFilter
          visible={showFilter}
          onResults={() => {}}
          onClose={() => {
            setShowFilter(false);
          }}
          onSearch={onFilter}
        />
        <Modal title={t("Compute Date Range")} visible={showComputeByDate} onOk={handleComputeByDate} onCancel={()=>setShowComputeByDate(false)}>
          <Form form={form} layout="vertical">
            <Form.Item label={t("From")} name="start">
              <DatePickerW />
            </Form.Item>
            <Form.Item label={t("To")} name="end">
              <DatePickerW />
            </Form.Item>
            <Form.Item label={t("Frequency")} name="frequency">
              <FrequencySelect placeholder={t("All")} allowClear />
            </Form.Item>
          </Form>
        </Modal>
      </DefaultPage>
    </div>
  );
};

export default AnnuityList;
