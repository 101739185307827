import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const FrequencySelect = (props) => {
  const [t] = useTranslation();

  const onSelect=(v)=>{
      props.onSelect&&props.onSelect(v);
  }
  const disabledValues=props.disabledValues||[];
  return (
    <Select style={props.style} value={props.value} onChange={props.onChange} onSelect={onSelect} placeholder={props.placeholder} allowClear={props.allowClear}>
      <Option value="m1" disabled={disabledValues.includes("m1")}>{t("Every month")}</Option>
      <Option value="y1" disabled={disabledValues.includes("y1")}>{t("Every year")}</Option>
      <Option value="q1" disabled={disabledValues.includes("q1")}>{t("Every quarter")}</Option>
      <Option value="s1" disabled={disabledValues.includes("s1")}>{t("Every semester")}</Option>
      <Option value="w1" disabled={disabledValues.includes("w1")}>{t("Every week")}</Option>
      <Option value="d1" disabled={disabledValues.includes("d1")}>{t("Every day")}</Option>
    </Select>
  );
};

export default FrequencySelect;
