import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select} from "antd";
import getCache from '../../Lib/Cache';

const RelationshipSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        load();
    },[])
    
    const load=contactId=>{
        setLoading(true);
        getCache("RepoRelationshipCatalog").then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
            }else message.error(r.msg)
        })
    }
    return (
        <div>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled} loading={loading}>
              {data.filter(p=>!props.principalType||p.principalType==props.principalType).map(p=><Select.Option value={p.id} key={p.id}>{p.name}</Select.Option> )}
          </Select>  
        </div>
    );
};

export default RelationshipSelect;