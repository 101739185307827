import React, { useState, useEffect } from "react";
import FormRenderModal from "../FormRenderModal";
import { Modal, message } from "antd";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";

const ReqirementsForm = (props) => {
  const [t] = useTranslation();

  const [next, doNext] = useState(0); //modal form submit

  const onFormOk = (v) => {
    const record = { ...props.formRecord, response: true, responseDate: new Date(), jFormValues: JSON.stringify(v) };
    exe("RepoLifeRequirement", { operation: "UPDATE", entity: record }).then((r) => {
      if (r.ok) {
        message.success(t("Form updated"));
        doNext(next + 1);
        props.onClose(r.outData[0]);
      }
    });
  };
  const onCancel = () => {
    props.onClose();
  };
  const formName = props.formRecord ? props.formRecord.code : null;
  const formValues = props.formRecord ? (props.formRecord.jFormValues ? JSON.parse(props.formRecord.jFormValues) : null) : null;
  return (
    <div>
      <Modal
        title={t("Required Form")}
        destroyOnClose
        visible={props.visible}
        onCancel={() => onCancel()}
        onOk={() => doNext(next + 1)}
        okButtonProps={{ disabled: props.formRecord ? props.formRecord.readOnly : false }}>
        <FormRenderModal modal formId={formName} next={next} onFormOk={onFormOk} valores={formValues} closing={!props.visible} />
      </Modal>
    </div>
  );
};

export default ReqirementsForm;
