import { FileOutlined, FolderOpenOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import InsuredObjectsDetail from "./InsuredObjectsDetail";
import ObjectDefinitionDetail from "./ObjectDefinitionDetail";
import ObjectRender from "./ObjectRender";
import EditableField from './../Shared/EditableField';

const InsuredObjects = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [t] = useTranslation();
  const [objectDefinitions, setObjectDefinitions] = useState([]);
  const [selectedObjectDefinitionId, setSelectedObjectDefinitionId] = useState(undefined);

  useEffect(() => {
    loadObjectDefinitions();
  }, []);

  useEffect(() => {
    if (props.change) {
      //used in change when props.change=true
      if (props.value) setData(JSON.parse(props.value));
      else if (props.newChange) load();
    } else if (props.policyId) {
      load();
    }
  }, [props.policyId, props.value]);

  const load = () => {
    setLoading(true);
    exe("RepoInsuredObject", { operation: "GET", filter: `lifePolicyId=${props.policyId}`, include: ["ObjectDefinition"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        if(props.onLoaded) props.onLoaded(r.outData);
      } else {
        message.error(r.msg);
      }
    });
    setLoading(true);
  };
  const onUpdate = (record) => {
    if (!record) {
      //delete
      const updatedData = data.filter((r) => r.id !== selected.id);
      setData(updatedData);
      if (props.change) props.onChange(JSON.stringify(updatedData));
      if(props.onLoaded) props.onLoaded(updatedData);
      return;
    }
    if (data.find((r) => r.id == record.id)) {
      //update
      const updatedData = data.map((r) => (r.id == record.id ? record : r));
      setData(updatedData);
      if (props.change) props.onChange(JSON.stringify(updatedData));
      if(props.onLoaded) props.onLoaded(updatedData);
    } else {
      //new
      record.ObjectDefinition = objectDefinitions.find((p) => p.id == selectedObjectDefinitionId);
      const updatedData = [...data, record];
      setData(updatedData);
      if (props.change) props.onChange(JSON.stringify(updatedData));
      if(props.onLoaded) props.onLoaded(updatedData);
    }
  };
  const onOpen = (r) => {
    setSelected(r);
    setDetailVisible(true);
    setSelectedObjectDefinitionId(r.objectDefinitionId);
  };
  const onBack=()=>{
    setSelected(undefined);
    setDetailVisible(false);
  }
  const loadObjectDefinitions = () => {
    setLoading(true);
    exe("RepoObjectDefinition", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        {
          setObjectDefinitions(r.outData);
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  const onNewObjectClick = (obj) => {
    setSelectedObjectDefinitionId(+obj.key);
    setDetailVisible(true);
    setSelected(undefined);
  };
  const onChangeAlias=(v,id)=>{
    exe("SetInsuredObjectAlias",{insuredObjectId:id,alias:v}).then(r=>{
      if(r.ok) {
        message.success(r.msg);
        //updating data
        const updatedData = data.map((r) => (r.id == id ? {...r,alias:v} : r));
        setData(updatedData);
      } else message.error(r.msg);
    })
  }
  return (
    <div>
      <div style={{ display: !detailVisible ? "block" : "none" }}>
        <div>
          <Dropdown
            disabled={!props.policyId || props.active || (props.max && data.length >= props.max)}
            overlay={
              <Menu onClick={onNewObjectClick}>
                {objectDefinitions
                  .filter((p) => props.objectTypes.find((t) => t.code == p.code))
                  .map((p) => (
                    <Menu.Item key={p.id}>{p.name}</Menu.Item>
                  ))}
              </Menu>
            }>
            <Button type="link" icon={<FileOutlined />} style={{ marginBottom: 5, marginRight: 5 }} disabled={!props.policyId || (props.max && data.length >= props.max)}>
              {t("New")}
            </Button>
          </Dropdown>
          <Button type="link" icon={<ReloadOutlined />} onClick={load} style={{ marginBottom: 5, marginRight: 5 }} disabled={!props.policyId || props.change}>
            {t("Reload")}
          </Button>
        </div>
        <Table dataSource={data} loading={loading} size="middle">
          <Table.Column title={t("Id")} dataIndex="id" />
          <Table.Column title={t("Code")} dataIndex={["ObjectDefinition","code"]} />
          <Table.Column title={t("Name")} dataIndex={["ObjectDefinition","name"]} />
          <Table.Column title={t("Alias")} dataIndex="alias" render={(v,r)=><EditableField value={v} onChange={v=>onChangeAlias(v,r.id)} />} />
          <Table.Column
            title={t("Actions")}
            key="actions"
            render={(v, r) => (
              <div>
                <Button type="link" icon={<FolderOpenOutlined />} onClick={() => onOpen(r)} />
              </div>
            )}
          />
        </Table>
      </div>

      {detailVisible&&<InsuredObjectsDetail
          policyId={props.policyId}
          objectDefinitionId={selectedObjectDefinitionId}
          value={selected}
          onBack={onBack}
          onUpdate={onUpdate}
          active={props.active}
          dynamicFormId={props.dynamicFormId}
          change={props.change}
        />}

    </div>
  );
};

export default InsuredObjects;
