import { Button, message, Tree } from 'antd';
import React, { useEffect, useState } from 'react';
import { exe } from '../../Lib/Dal';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined,SaveOutlined } from '@ant-design/icons';

const OrganizationStructure = (props) => {
    
    const [t]=useTranslation();
    const [gData, setGData] = useState([]);
    const [expandedKeys] = useState(['0']);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData=()=>{
        setLoading(true);
        exe("RepoOrganization",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                //setting the position of each element according to its parentId, name->title, code->code
                let data = r.outData;
                let tree = [];
                let map = {};
                data.forEach(e=>{
                    map[e.id] = {key:e.id,title:e.name,code:e.code,children:[],expanded:true};
                });
                data.forEach(e=>{
                    if(e.parentId){
                        map[e.parentId].children.push(map[e.id]);
                    }else{
                        tree.push(map[e.id]);
                    }
                });
                setGData(tree);


            }else message.error(r.msg);
        })
    }

    const onDragEnter = (info) => {
      console.log(info);
      // expandedKeys 需要受控时设置
      // setExpandedKeys(info.expandedKeys)
    };
    const onDrop = (info) => {
      console.log(info);
      const dropKey = info.node.key;
      const dragKey = info.dragNode.key;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      const loop = (data, key, callback) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === key) {
            return callback(data[i], i, data);
          }
          if (data[i].children) {
            loop(data[i].children, key, callback);
          }
        }
      };
      const data = [...gData];
  
      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert 示例添加到头部，可以是随意位置
          item.children.unshift(dragObj);
        });
      } else if (
        (info.node.props.children || []).length > 0 &&
        // Has children
        info.node.props.expanded &&
        // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, (item) => {
          item.children = item.children || [];
          // where to insert 示例添加到头部，可以是随意位置
          item.children.unshift(dragObj);
          // in previous version, we use item.children.push(dragObj) to insert the
          // item to the tail of the children
        });
      } else {
        let ar = [];
        let i;
        loop(data, dropKey, (_item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      setGData(data);
    };
    const save = () => {
      console.log(gData);
      //setting the parentId of each element according to its children position, key->id, title->name, code->code, data should be flat, submitting al the data
        let data = [];

        const loop = (d,parentId) => {
            d.forEach(e=>{
                data.push({id:e.key,name:e.title,parentId:parentId,code:e.code});
                if(e.children) loop(e.children,e.key);
            });
        }
        loop(gData,undefined);
        console.log(data);
        
        setLoading(true);
        exe("RepoOrganization",{operation:"MERGE",bulkJson:JSON.stringify(data)}).then(r=>{
            setLoading(false);
            if(r.ok) message.success(t("Saved"));
            else message.error(r.msg);
        })


        
    }
    return (
        <div>
            <Button type="link" onClick={getData} loading={loading} icon={<ReloadOutlined />}>{t("Refresh")}</Button>
            <Button type="link" onClick={save} loading={loading} icon={<SaveOutlined />}>{t("Save")}</Button>
             <Tree
                draggable
                blockNode
                onDragEnter={onDragEnter}
                onDrop={onDrop}
                treeData={gData}
                showLine
                defaultExpandAll={true}

    />
        </div>
    );
};

export default OrganizationStructure;