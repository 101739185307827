import React from "react";
import { d3, CalHeatMap } from "sis11deps";
import { exe } from "Lib/Dal";
import moment from "moment";
import {withTranslation} from "react-i18next";

class HeatMapPolicies extends React.Component {
  constructor(props) {
    super(props);
    this.hmInstance = null;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchAndRenderHeatmap();
  }

  componentDidUpdate(prevProps) {
    if (this.props.year !== prevProps.year) {
      this.updateHeatmap();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.hmInstance) {
      this.hmInstance.destroy();
      this.hmInstance = null;
    }
  }

  fetchAndRenderHeatmap = () => {
    exe("GetHeatmap", { mode: "policies" }).then((r) => {
      if (!this._isMounted) return;
      if (!r.ok) return;
      if (this.props.onLoad) this.props.onLoad();

      const data = r.outData.reduce((acc, item) => {
        acc[item.timestamp] = item.count;
        return acc;
      }, {});

      this.renderHeatmap(data);
    });
  }

  renderHeatmap = (data) => {
    if (!this._isMounted) return;

    const year = this.props.year || new Date().getFullYear();
    const hm = new CalHeatMap();
    hm.init({
      domain: "month",
      subDomain: "day",
      range: 12,
      start: new Date(year, 0, 1),
      data: data,
      itemName:[this.props.t("policy"), this.props.t("policies")],
      subDomainTitleFormat: { empty: "{date}", filled: "{count} {name} - {date}" },
      subDomainDateFormat: function(date) {
          return moment(date).local().format("dddd MMMM Do, YYYY");
      },
      domainLabelFormat: function(date) {
        return moment(date).local().format("MMMM");
      },
      onClick: (date, nb) => {
        window.parent.location.hash =
          "#/policies?date=" + date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
      },
    });
    this.hmInstance = hm;
  }

  updateHeatmap = () => {
    if (this.hmInstance && this._isMounted) {
      this.hmInstance.jumpTo(new Date(this.props.year, 0, 1), true);
    }
  }

  render() {
    return <div id="cal-heatmap" />;
  }
}

export default withTranslation()(HeatMapPolicies);
